import styled, { css } from "styled-components/macro";
import { ReactComponent as LogoutSvg } from "assets/logout.svg";
import { useLogout } from "reduxStuff";
import tw from "twin.macro";

const Button = styled.button`
  ${css`
    @media (min-width: 2560px) {
      width: 32px !important;
      height: 32px !important;
      padding: 8px !important;
    }
  `}
  ${tw`
    fixed
    cursor-pointer
    xl:w-[24px]
    xl:h-[24px]
    lg:w-[20px]
    lg:h-[20px]
    md:w-[16px]
    md:h-[16px]
    text-white
    bg-[#1f1f26]
    xl:p-[6px]
    lg:p-[5px]
    md:p-[4px]
    border-radius[0 0 2px 2px]
  `}
  top: 0;
  right: 100px;

  * {
    transition: fill 0.2s ease-in-out;
  }

  :hover * {
    fill: #c02e28;
  }
`;

const LogoutButton = () => {
  const logout = useLogout();

  return (
    <Button onClick={logout}>
      <LogoutSvg />
    </Button>
  );
};

export default LogoutButton;
