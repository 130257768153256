import { useRef, useState, useEffect } from "react";
import { sha256 } from "js-sha256";
import { useLogin } from "reduxStuff";
import { useHistory } from "react-router-dom";
import jsCookie from "js-cookie";
import InputRaw from "components/Input";
import ButtonRaw from "components/Button";
import Container from "components/Container";
import ContainerTag from "components/ContainerTag";
import Spinner from "components/Spinner";
import { apiOrigin } from "components/Utilidades";
import tw from "twin.macro";
import styled from "styled-components";

const Bg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const InputLogin = tw(InputRaw)`
  m-[0px 16px 3px 0px]
  md: w-auto
  w-[100%]
`;

const Form = tw.form`
  md:flex
  md:justify-center
  md:items-center
  m-[32px auto 0 auto]
  max-w-[100%]
`;

const Button = tw(ButtonRaw)`
  m-[3px 0 0 0]
  md:m-[0 16px]
  w-[100%]
`;

const BadLoginMessage = styled.span`
  margin-top: 16px;
  color: white;
  text-align: center;
`;

const handleLogin = async (username, password) => {
  password = sha256(password);
  let loginInfo;

  const name = window.location.host.split(".");
  const url = name[0].charAt(0).toUpperCase() + name[0].slice(1);
  try {
    let response = await fetch(apiOrigin + `/login`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      body: `username=${username}&password=${password}&url=${url}`,
    });

    let body = await response.json();

    loginInfo = { ...body, status: response.status };
  } catch (e) {
    loginInfo = { status: -1 };
  }

  return loginInfo;
};

const Login = () => {
  const username = useRef(null);
  const password = useRef(null);
  const submitButton = useRef(null);
  const login = useLogin();
  const history = useHistory();
  const [badLogin, setBadLogin] = useState(false);
  const [badLogin2, setBadLogin2] = useState(false);
  const [logingIn, setLogingIn] = useState(false);

  useEffect(() => {
    if (jsCookie.get("role") === "admin") history.replace("/admin");
    if (
      jsCookie.get("role") === "operator" ||
      jsCookie.get("role") === "supervisor" ||
      jsCookie.get("role") === "shift" ||
      jsCookie.get("role") === "support"
    )
      history.replace("/operador");
  }, [history]);

  return (
    <Bg>
      <Container>
        {logingIn ? <Spinner /> : null}
        <ContainerTag txt="Login" position="left" />

        <Form>
          <InputLogin ref={username} type="text" placeholder="Usuario" autoComplete="current-password" />
          <InputLogin ref={password} type="password" placeholder="Contraseña" autoComplete="username" />

          <Button
            primario
            ref={submitButton}
            onClick={async e => {
              setLogingIn(true);
              username.current.disabled = true;
              password.current.disabled = true;
              submitButton.current.disabled = true;
              e.preventDefault();
              let loginInfo = await handleLogin(username.current.value, password.current.value);
              setLogingIn(false);
              username.current.disabled = false;
              password.current.disabled = false;
              submitButton.current.disabled = false;
              if (loginInfo.status === 200) {
                if (+loginInfo.agency_id === 1) {
                  if (+loginInfo.id === 1 && loginInfo.role === "admin") {
                    login(loginInfo.access_token, loginInfo.role, loginInfo.id, loginInfo.agency_id, loginInfo.shift);
                    if (loginInfo.role === "admin") history.replace("/admin");
                    else if (
                      loginInfo.role === "operator" ||
                      loginInfo.role === "support" ||
                      loginInfo.role === "shift" ||
                      loginInfo.role === "supervisor"
                    )
                      history.replace("/operador");
                  } else {
                    setBadLogin2(true);
                    setBadLogin(false);
                  }
                } else {
                  login(loginInfo.access_token, loginInfo.role, loginInfo.id, loginInfo.agency_id, loginInfo.shift);
                  if (loginInfo.role === "admin") history.replace("/admin");
                  else if (
                    loginInfo.role === "operator" ||
                    loginInfo.role === "support" ||
                    loginInfo.role === "shift" ||
                    loginInfo.role === "supervisor"
                  )
                    history.replace("/operador");
                }
              } else if (loginInfo.status === 400) {
                setBadLogin2(false);
                setBadLogin(true);
              }
            }}
          >
            Log In
          </Button>
        </Form>
        {badLogin ? <BadLoginMessage>Se ha equivocado al introducir el usuario o contraseña</BadLoginMessage> : null}
        {badLogin2 ? <BadLoginMessage>Esta cuenta no esta asignada a ninguna agencia</BadLoginMessage> : null}
      </Container>
    </Bg>
  );
};

export default Login;
