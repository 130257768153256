import { useEffect, useRef, useState } from "react";
import CartaPngUrl from "assets/carta.png";
import RegaloPngUrl from "assets/regalo.png";
import ErrorPngUrl from "assets/error.png";
import WarningPngUrl from "assets/warning.png";
import jsCookie from "js-cookie";
import { css } from "styled-components/macro";
import pingSound from "assets/audio/ping.wav";
import popSound from "assets/audio/pop.wav";
import errorSound from "assets/audio/error.wav";
import metalPingSound from "assets/audio/metalping.wav";
import { queryCache, usePaginatedQuery, useQuery } from "react-query";
import { toast } from "react-toastify";
import { ReactComponent as WarningSvg } from "assets/warning.svg";
import { ReactComponent as ChatSvg } from "assets/chat.svg";
import { ReactComponent as EmailArrowLeftRedSvg } from "assets/carta-recibida.svg";
import { ReactComponent as EmailArrowRightGreenSvg } from "assets/carta-enviada.svg";
import { ReactComponent as PresentSvg } from "assets/present.svg";
import { ReactComponent as MedalSvg } from "assets/medal.svg";
import { ReactComponent as OnlineChatSvg } from "assets/online-chat.svg";
import { ReactComponent as ProfileVideoSvg } from "assets/profile-video.svg";
import { ReactComponent as QuestionSvg } from "assets/question.svg";
import { ReactComponent as ViewedAttachmentSvg } from "assets/viewed-attachment.svg";
import { ReactComponent as WebcamSvg } from "assets/webcam.svg";
import { ReactComponent as GiftBagSvg } from "assets/gift-bag.svg";
import { ReactComponent as MicrophoneSvg } from "assets/microphone.svg";
import { ReactComponent as MissedInviteSvg } from "assets/missed-invite.svg";
import { ReactComponent as ManualWriteoffSvg } from "assets/manual-writeoff.svg";
import { ReactComponent as MissedAnswerSvg } from "assets/missed-answer.svg";
import { ReactComponent as MissedMessageSvg } from "assets/missed-message.svg";
import { ReactComponent as MissedEmailSvg } from "assets/missed-email.svg";
import { ReactComponent as UnansweredEmailSvg } from "assets/unanswered-email.svg";
import { ReactComponent as MissedVideoInviteSvg } from "assets/missed-video-invite.svg";
import { ReactComponent as MissedPresentSvg } from "assets/missed-present.svg";
import { ReactComponent as NoWifiSvg } from "assets/no-wifi.svg";
import { ReactComponent as Multa } from "assets/multa.svg";
import { ReactComponent as MultaCartaSvg } from "assets/multa-carta.svg";
//import { ReactComponent as MultaMingleSvg } from "assets/multa-mingle.svg";
import { ReactComponent as Chat2Svg } from "assets/chat2.svg";
import { ReactComponent as CartaSvg } from "assets/carta.svg";
import { ReactComponent as Foto2Svg } from "assets/foto2.svg";
import { ReactComponent as VideoStreamSvg } from "assets/video-stream.svg";
import { ReactComponent as MicrophoneReceiveSvg } from "assets/microphone-listening.svg";
import { ReactComponent as MicrophoneSendSvg } from "assets/microphone-send.svg";
import { ReactComponent as ManMultaSvg } from "assets/man_error.svg";
import { ReactComponent as CorazonSvgRaw } from "assets/heart.svg";
import { ReactComponent as CorazonGreenSvgRaw } from "assets/heart-green.svg";
import { ReactComponent as CoronaSvgRaw } from "assets/crown.svg";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as GlobeSvg } from "assets/globe.svg";
import { componenteRadial } from "./Mixins";

const CorazonSvg = styled(CorazonSvgRaw)`
  ${componenteRadial}
`;

const CorazonGreenSvg = styled(CorazonGreenSvgRaw)`
  ${componenteRadial}
`;

const CoronaSvg = styled(CoronaSvgRaw)`
  ${componenteRadial}
`;
/**
 * @param  {React.Component | Array<React.Component>} Componente Componente (lo mas seguro un "svg")
 * @param  {Number} width Ancho en pixeles del componente
 * @param  {Number} r1 Radio del elemento usado como origen (Menor que r2 para evitar que el componente quede por dentro)
 * @param  {Number} r2 Distancia total desde el centro hasta el punto deseado
 * @param  {Number} cantidad Cantidad de componentes que se quieran alrededor
 * @param  {Number} anguloSeparacion Angulo en grados que se quiera entre cada componente
 * @return {Array<React.Component>} Array de componentes listo para ser interpolado en jsx
 *
 * Si en "Componente" se manda un array, "cantidad" sera ignorado.
 */
export function converterDateAct(fecha, createDate = true) {
  const milie = new Date().getTimezoneOffset() * 60 * 1000;
  if (createDate === false) return fecha - milie;
  const miliedate = fecha.getTime();
  return new Date(miliedate - milie);
}

export const fetchAgency = async key => {
  return await customFetch(`/agency/list`);
};

export const fetchTurnos = async key => {
  return await customFetch("/shifts/list");
};

export const SummaryValue = styled.span`
  @media (min-width: 2560px) {
    font-size: 0.65rem !important;
    line-height: 1rem !important;
  }
  @media (min-height: 1000px) and (max-width: 767px) {
    font-size: 0.65rem !important;
    line-height: 1rem !important;
  }
  @media (max-height: 999px) and (max-width: 767px) {
    font-size: 0.4rem !important;
  }
  ${tw`
    2xl:font-size[0.54rem]
    xl:font-size[0.42rem]
    lg:font-size[0.3rem]
    font-size[0.25rem]
    font-bold
    whitespace-nowrap
  `}
`;

export const fetchCount = async (
  key,
  role,
  type,
  sort,
  operatorType,
  pauseperfilView,
  perfilTaken,
  AgencyId = +jsCookie.get("agency_id"),
) => {
  if (type === "agencias" || type === "codigos" || role !== "admin") return null;

  return await customFetch(
    `/agency/filter-countries?type=${type}&sort=${sort}&operatorType=${operatorType}&pauseperfilView=${pauseperfilView}&perfilTaken=${perfilTaken}&AgencyId=${AgencyId}`,
  );
};
const padToTwoDigits = num => {
  return num.toString().padStart(2, "0");
};

export const convertMsToHHMMSS = mstTime => {
  let hora = 0;
  let minutos = 0;

  hora = Math.floor(mstTime / (60 * 60));
  minutos = Math.floor((mstTime / (60 * 60) - hora) * 60);
  return `${padToTwoDigits(hora)}:${padToTwoDigits(minutos)}H`;
};

export const tags = [
  "$",
  "2",
  "a",
  "autoreply.disabled",
  "bonus-customer",
  "camshare.exists",
  "cheers.introduced",
  "cheers.introduction",
  "cheers.onboarding",
  "cherish.coregistration",
  "credits.accounts.autopurchase.restricted",
  "credits.accounts.birthday",
  "credits.accounts.debited..23d",
  "credits.accounts.existed",
  "credits.accounts.nonempty",
  "credits.accounts.vip",
  "credits.accounts.virtual.nonempty",
  "credits.commissions.cam",
  "credits.commissions.dialogs.media.high",
  "credits.commissions.dialogs.media.low",
  "credits.commissions.dialogs.messages.high",
  "credits.commissions.dialogs.messages.low",
  "credits.experiment.roulette.group1",
  "credits.limited.experiment.group1",
  "credits.limited.experiment.group2",
  "credits.limited.experiment.group3",
  "credits.mall.android.alternative.method",
  "credits.mall.prices.well.known",
  "credits.roulette.creditpack",
  "cupid.trial",
  "cupid.trial.first.login",
  "customer",
  "customer.antifraud",
  "customer.purchase",
  "declined",
  "dialogs.automation.autosuspend.disabled",
  "dialogs.automation.invitation.video.priority",
  "dialogs.automation.production.introductory.exists",
  "dialogs.automation.production.invitation.exists",
  "dialogs.automation.suspended",
  "dialogs.messages.promoter",
  "dialogs.messages.promoter.videochat",
  "dialogs.tariffication.interval.exists",
  "dialogs.tariffication.introduced",
  "e",
  "email.confirmation.notification.sent",
  "engaged",
  "exo.cards.known",
  "exo.export.restricted",
  "exo.paypal.known",
  "experiment.adaptive.invites.increase",
  "experiment.membership.organic",
  "experiments.adaptive.push",
  "experiments.adaptive.push.fast",
  "experiments.adaptive.push.optimal",
  "experiments.aura.products.promotions",
  "experiments.cheers.easter.voter",
  "experiments.cheers.profile.available",
  "experiments.cheers.promoter.allowed",
  "experiments.contacts.favorite",
  "experiments.credits.fastbuy",
  "experiments.dialogs.topics",
  "experiments.email.required",
  "experiments.familiar",
  "experiments.invitation.videocall",
  "experiments.letters.introduction",
  "experiments.letters.introduction.freeletters",
  "experiments.phones.popup",
  "experiments.phones.popupbanner",
  "experiments.popularmember",
  "experiments.popularmember.general",
  "experiments.popularmembers.general",
  "experiments.popularmembers.general.purchaseform",
  "experiments.popularmembers.general.search",
  "experiments.popularmembers.general.subscription",
  "experiments.roulette.calls.autocontinue",
  "experiments.roulette.matches.unconditional",
  "experiments.roulette.membership",
  "experiments.search.mixed",
  "experiments.transparent.purchase",
  "experiments.users.confirmed",
  "experiments.xerox2",
  "freetrial.experiment.v2",
  "hidden",
  "i",
  "imported",
  "integrated",
  "invitation.recording.disabled",
  "membership",
  "mobile",
  "mobile.androidapp",
  "mobile.iosapp",
  "mute",
  "o",
  "payment.method.nongoogle",
  "presence.cam",
  "presence.exists",
  "presence.history.iosapp",
  "presence.iosapp",
  "presence.mobile.exists",
  "presence.mobileapp",
  "presence.mobilecam",
  "presence.webapp",
  "presents.available",
  "promoter.7d",
  "scam",
  "sophia.audit.disable",
  "streamer",
  "subscriber",
  "subscription",
  "subscription.onhold",
  "suspended",
  "traffic.inorganic",
  "triptogether",
  "u",
  "unapproved",
  "unique.fingerprint",
  "user.copied",
  "users.1h",
  "users.30d",
  "users.3d",
  "users.confirmed",
  "users.email.confirmation.required",
  "users.events.letters.processed",
  "users.housing",
  "users.moderation",
  "users.prepromoter",
  "users.suspended",
  "users.uncopiable",
  "videochat.exists",
  "welcome.notification.mobile",
  "y",
  "£",
  "€",
];

export const DivRadio = styled.div`
  @media (min-width: 2560px) {
    grid-gap: 1rem !important;
  }

  ${tw`
    flex
    items-center
    lg:gap-2
    md:gap-1
    md:letter-spacing[0.08em]
  `}

  input[type="radio"] {
    @media (min-width: 2560px) {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px !important;
      border: 2px solid #4c4c3f !important;
    }
    ${tw`
      xl:w-[12px]
      xl:h-[12px]
      lg:w-[8px]
      lg:h-[8px]
      md:w-[6px]
      md:h-[6px]
      cursor-pointer
      md:mr-[2px]
      border-radius[50%]
      md:border[1px solid #4c4c3f]
    `}
    appearance: none;
  }

  input[type="checkbox"] {
    @media (min-width: 2560px) {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px !important;
      border: 2px solid #4c4c3f !important;
    }
    ${tw`
      xl:w-[12px]
      xl:h-[12px]
      lg:w-[8px]
      lg:h-[8px]
      md:w-[6px]
      md:h-[6px]
      cursor-pointer
      md:mr-[2px]
      border-radius[10%]
      md:border[1px solid #4c4c3f]
    `}
    appearance: none;
  }

  input:checked {
    @media (min-width: 2560px) {
      border: 6px solid #4c4c3f !important;
    }
    ${tw`
      xl:border[6px solid #4c4c3f]
      lg:border[4px solid #4c4c3f]
      md:border[4px solid #4c4c3f]
    `}
  }
`;

export const CalcShift = (agency_id, shift) => {
  function DateConvertMili(date = new Date()) {
    return (
      [
        date.getUTCFullYear(),
        (date.getUTCMonth() + 1).toString().padStart(2, "0"),
        date.getUTCDate().toString().padStart(2, "0"),
      ].join("-") +
      "T" +
      [
        date.getUTCHours().toString().padStart(2, "0"),
        date.getUTCMinutes().toString().padStart(2, "0"),
        date.getUTCSeconds().toString().padStart(2, "0"),
      ].join(":") +
      "." +
      date.getUTCMilliseconds().toString().padStart(2, "0")
    );
  }

  const agencias = queryCache.getQueryData(["agency"]);

  if (agency_id === undefined || shift === undefined)
    return {
      start: null,
      end: null,
      utc: null,
    };
  const agency = agencias?.find(agencia => agencia.id === agency_id);
  const UTC = new Date();

  if (agency) {
    const calc = [agency.startday, agency.startnight, agency.startevening];
    const min = calc.reduce((prev, cur) => (cur < prev ? cur : prev));

    const newUTC = new Date(UTC.getTime() - (min + 1) * 60 * 60 * 1000);

    const IniNightStart = new Date(
      newUTC.getTime() -
        (newUTC.getUTCHours() * 60 * 60 * 1000 +
          newUTC.getUTCMinutes() * 60 * 1000 +
          newUTC.getUTCSeconds() * 1000 +
          newUTC.getUTCMilliseconds()) +
        agency.startnight * 60 * 60000,
    );

    const IniDayStart = new Date(IniNightStart.getTime() + 8 * 60 * 60000);
    const IniLateStart = new Date(IniDayStart.getTime() + 8 * 60 * 60000);
    const IniLateEnd = new Date(IniLateStart.getTime() + 8 * 60 * 60000);

    if (shift === 0) {
      return {
        start: DateConvertMili(IniDayStart),
        end: DateConvertMili(IniLateStart),
        utc: DateConvertMili(UTC),
      };
    } else if (shift === 1) {
      return {
        start: DateConvertMili(IniNightStart),
        end: DateConvertMili(IniDayStart),
        utc: DateConvertMili(UTC),
      };
    } else if (shift === 2) {
      return {
        start: DateConvertMili(IniLateStart),
        end: DateConvertMili(IniLateEnd),
        utc: DateConvertMili(UTC),
      };
    }
  }

  return {
    start: null,
    end: null,
    utc: null,
  };
};

export const organizadorRadial = (Componente, width, r1, r2, cantidad, anguloSeparacion) => {
  let lista = [];
  const totalObjetos = Array.isArray(Componente) ? Componente.length : cantidad;

  const anguloInicial =
    totalObjetos % 2 === 0
      ? 90 + anguloSeparacion + (totalObjetos / 2 - 1) * 2 * anguloSeparacion
      : 90 + ((totalObjetos - 1) / 2) * 2 * anguloSeparacion;

  if (Array.isArray(Componente)) {
    for (let i = 0; i < Componente.length; i++) {
      const Comp = Componente[i];

      lista.push(
        <Comp key={Math.random()} width={width} angulo={anguloInicial - i * 2 * anguloSeparacion} r1={r1} r2={r2} />,
      );
    }
  } else {
    for (let i = 0; i < cantidad; i++) {
      lista.push(
        <Componente
          key={Math.random()}
          width={width}
          angulo={anguloInicial - i * 2 * anguloSeparacion}
          r1={r1}
          r2={r2}
        />,
      );
    }
  }

  return lista;
};

export const organizadorRadialCorazones = (width, r1, r2, cantidad, anguloSeparacion) => {
  let lista = [];

  const totalObjetosCorona = Array.isArray(CoronaSvg) ? CoronaSvg.length : Number(cantidad[0]);
  const totalObjetosGreen = Array.isArray(CorazonGreenSvg) ? CorazonGreenSvg.length : Number(cantidad[1]);
  const totalObjetosRojos = Array.isArray(CorazonSvg) ? CorazonSvg.length : Number(cantidad[2]);

  // const anguloInicialCorona =
  //   totalObjetosCorona % 2 === 0
  //     ? 90 + anguloSeparacion + (totalObjetosCorona / 2 - 1) * 2 * anguloSeparacion
  //     : 90 + ((totalObjetosCorona - 1) / 2) * 2 * anguloSeparacion;

  // const anguloInicialGreen =
  //   totalObjetosGreen % 2 === 0
  //     ? 90 + anguloSeparacion + (totalObjetosGreen / 2 - 1) * 2 * anguloSeparacion
  //     : 90 + ((totalObjetosGreen - 1) / 2) * 2 * anguloSeparacion;

  // const anguloInicialRojos =
  //   totalObjetosRojos % 2 === 0
  //     ? 90 + anguloSeparacion + (totalObjetosRojos / 2 - 1) * 2 * anguloSeparacion
  //     : 90 + ((totalObjetosRojos - 1) / 2) * 2 * anguloSeparacion;
  const totalObjetos = totalObjetosCorona + totalObjetosGreen + totalObjetosRojos;
  const anguloInicial =
    totalObjetos % 2 === 0
      ? 90 + anguloSeparacion + (totalObjetos / 2 - 1) * 2 * anguloSeparacion
      : 90 + ((totalObjetos - 1) / 2) * 2 * anguloSeparacion;

  if (Array.isArray(CorazonSvg)) {
    for (
      let i = CorazonGreenSvg.length + CoronaSvg.length;
      i < CorazonSvg.length + CorazonGreenSvg.length + CoronaSvg.length;
      i++
    ) {
      const Comp = CorazonSvg[i];

      lista.push(
        <Comp key={Math.random()} width={width} angulo={anguloInicial - i * 2 * anguloSeparacion} r1={r1} r2={r2} />,
      );
    }
  } else {
    for (
      let i = Number(cantidad[1]) + Number(cantidad[0]);
      i < Number(cantidad[1]) + Number(cantidad[0]) + Number(cantidad[2]);
      i++
    ) {
      lista.push(
        <CorazonSvg
          key={Math.random()}
          width={width}
          angulo={anguloInicial - i * 2 * anguloSeparacion}
          r1={r1}
          r2={r2}
        />,
      );
    }
  }

  if (Array.isArray(CorazonGreenSvg)) {
    for (let i = CoronaSvg.length; i < CorazonGreenSvg.length + CoronaSvg.length; i++) {
      const Comp = CorazonGreenSvg[i];

      lista.push(
        <Comp key={Math.random()} width={width} angulo={anguloInicial - i * 2 * anguloSeparacion} r1={r1} r2={r2} />,
      );
    }
  } else {
    for (let i = Number(cantidad[0]); i < Number(cantidad[1]) + Number(cantidad[0]); i++) {
      lista.push(
        <CorazonGreenSvg
          key={Math.random()}
          width={width}
          angulo={anguloInicial - i * 2 * anguloSeparacion}
          r1={r1}
          r2={r2}
        />,
      );
    }
  }
  if (Array.isArray(CoronaSvg)) {
    for (let i = 0; i < CoronaSvg.length; i++) {
      const Comp = CoronaSvg[i];
      lista.push(
        <Comp key={Math.random()} width={width} angulo={anguloInicial - i * 2 * anguloSeparacion} r1={r1} r2={r2} />,
      );
    }
  } else {
    for (let i = 0; i < Number(cantidad[0]); i++) {
      lista.push(
        <CoronaSvg
          key={Math.random()}
          width={width}
          angulo={anguloInicial - i * 2 * anguloSeparacion}
          r1={r1}
          r2={r2}
        />,
      );
    }
  }
  return lista;
};

export function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export const tiempoActualizacion = process.env.NODE_ENV === "production" ? 3 * 60 * 1000 : 99999999999;

function getexitfullscreen() {
  return (
    document.exitFullscreen ||
    document.webkitExitFullscreen ||
    document.mozCancelFullScreen ||
    document.msExitFullscreen
  );
}

function getreqfullscreen() {
  var root = document.documentElement;
  return (
    root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen
  );
}

function getfullscreenelement() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}

var globalreqfullscreen = getreqfullscreen(); // get supported version of requestFullscreen()
var globalexitfullscreen = getexitfullscreen(); // get supported version of document.exitFullscreen()

export const toggleFullscreen = e => {
  var target = e.target;
  if (target.tagName === "IMG" && target.classList.contains("canfullscreen")) {
    if (getfullscreenelement() == null) {
      globalreqfullscreen.call(target);
    } else {
      globalexitfullscreen.call(document);
    }
  }
};

export const fetchLogsCv = async (key, role, type, profileId, clientId, operatorId, operatorSee, page) => {
  if (type === "operator") return null;
  return await customFetch(
    `/operators/cv-history?profileId=${profileId}&clientId=${clientId}&operatorId=${operatorId}&operatorSee=${operatorSee}&role=${role}&page=${page}`,
  );
};

export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [handler, ref]);
};

export const apiOrigin = "https://api.scalebot.net";
// process.env.NODE_ENV === "production" ? "https://api." + window.location.host : "https://api." + window.location.host;

export const diaDeLaSemana = dia => {
  switch (dia) {
    case 0:
      return "Domingo";

    case 1:
      return "Lunes";

    case 2:
      return "Martes";

    case 3:
      return "Miercoles";

    case 4:
      return "Jueves";

    case 5:
      return "Viernes";

    case 6:
      return "Sabado";

    default:
      return "?????";
  }
};

export const mesDelYear = mes => {
  switch (mes) {
    case 0:
      return "Enero";

    case 1:
      return "Febrero";

    case 2:
      return "Marzo";

    case 3:
      return "Abril";

    case 4:
      return "Mayo";

    case 5:
      return "Junio";

    case 6:
      return "Julio";

    case 7:
      return "Agosto";

    case 8:
      return "Septiembre";

    case 9:
      return "Octubre";

    case 10:
      return "Noviembre";

    case 11:
      return "Diciembre";

    default:
      return "?????";
  }
};

Notification.requestPermission();

/**
 * Manda una notificacion al sistema con el mensaje deseado.
 * Se muestra un icono diferente dependiendo de iconType.
 * @param  {string} mensaje
 * @param  {"carta" | "bonus" | "writeoff" | "fineAlert"} iconType
 */
export const mandarNotificacion = async (mensaje, iconType) => {
  let icon;
  let sound;
  switch (iconType) {
    case "carta":
      icon = CartaPngUrl;
      sound = popSound;
      break;
    case "bonus":
      icon = RegaloPngUrl;
      sound = pingSound;
      break;
    case "writeoff":
      icon = ErrorPngUrl;
      sound = errorSound;
      break;
    case "fineAlert":
      icon = WarningPngUrl;
      sound = metalPingSound;
      break;
    default:
      break;
  }
  let silent = true;
  await new Audio(sound).play().catch(() => {
    silent = false;
  });
  new Notification(mensaje, { icon, silent });
};

/**
 * Hace fetch a apiOrigin + url mandando sessionToken en el header x-api-key.
 * Interpreta la respuesta como JSON, la parsea y la devuelve.
 * @param  {string} url
 * @param  {RequestInit} opciones
 * @returns JSON parseado
 */
export const customFetch = async (url, opciones = {}) => {
  const sessionToken = jsCookie.get("sessionToken");
  const { headers, ...restoDeOpciones } = opciones;

  const data = await (
    await fetch(apiOrigin + url, {
      headers: { "X-Api-key": sessionToken, ...headers },
      ...restoDeOpciones,
    })
  ).json();

  if (data?.name === "Unauthorized") {
    if (data.status === 401) {
      return () => {
        jsCookie.remove("sessionToken");
        jsCookie.remove("role");
        jsCookie.remove("id");
        queryCache.removeQueries();
        window.location.reload();
      };
    }
  }

  return data;
};

export const fetchSuperBotMedia = async (key, perfilId, media, id) => {
  return await customFetch(`/drive-files/count-media?profileId=${perfilId}&type=${media}&client=${id}`);
};

export const renombrarPropiedades = obj => {
  if (obj === null || obj === undefined) return obj;
  const objRenombrado = { ...obj };

  const intercambiarPropiedad = (vieja, nueva) => {
    objRenombrado[nueva] = obj[vieja];
    delete objRenombrado[vieja];
  };

  const paresDeCambio = [
    ["name", "nombre"],
    ["age", "edad"],
    ["client_id", "clienteId"],
    ["client", "cliente"],
    ["profile_id", "perfilId"],
    ["profile", "perfil"],
    ["stars", "estrellas"],
    ["crowns", "coronas"],
    ["hearts", "corazones"],
    ["operators", "operadores"],
    ["profiles", "perfiles"],
    ["pauseOperator", "operadorDePausa"],
    ["operators", "operadores"],
    ["profiles", "perfiles"],
    ["paused_profiles", "perfilesPausados"],
    ["clients", "clientes"],
    ["user_id", "userId"],
    ["extra_user_id", "extraUserId"],
    ["sent_amount", "cantidadEnviada"],
    ["available_clients", "clientesDisponibles"],

    ["supervisor_id", "supervisorId"],

    ["progress_total", "progresoTotal"],
    ["progress_day", "progresoDia"],
    ["progress_month", "progresoMes"],
    ["progress_max_day", "progresoMaximoDia"],
    ["progress_max_month", "progresoMaximoMes"],

    ["photo", "foto"],
    ["free", "libre"],
    ["amolatina_id", "amolatinaId"],
    ["paused", "pausado"],
    ["milestone_day", "metaDia"],
    ["milestone_month", "metaMes"],
    ["mes_summary", "mesSummary"],
    ["dia_summary", "diaSummary"],

    ["task_mails", "tareaCartas"],
    ["task_photos", "tareaFotos"],
    ["task_videos", "tareaVideos"],
    ["letters", "cartas"],

    ["active", "activo"],
    ["surname", "apellido"],
    ["isOnline", "online"],
    ["fine", "multas"],
    ["fineAlerts", "alertaMultas"],
    ["username", "cedula"],
    ["rank", "medallas"],
    ["missed_days", "faltasOperador"],
    ["free_days", "diasLibresOperador"],
    ["day", "dia"],
    ["month", "mes"],

    ["operator", "operador"],
    ["pause_operator", "operadorDePausa"],

    ["positive", "positivo"],
    ["negative", "negativo"],
    ["main_agency", "agenciaPrincipal"],
    ["sub_agencies", "agenciasSecundarias"],
  ];

  paresDeCambio.forEach(par => {
    if (obj[par[0]] === undefined) return;
    return intercambiarPropiedad(...par);
  });

  return objRenombrado;
};

export const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, "0");

export const renombrarPropiedadesAnidadas = obj => {
  if (obj === null || obj === undefined) return obj;

  if (Array.isArray(obj)) {
    const arrRenombrado = [...obj];
    for (const index in arrRenombrado) {
      arrRenombrado[index] = renombrarPropiedadesAnidadas(arrRenombrado[index]);
    }

    return arrRenombrado;
  } else if (typeof obj === "object") {
    const objRenombrado = renombrarPropiedades(obj);
    for (const propiedad in objRenombrado) {
      objRenombrado[propiedad] = renombrarPropiedadesAnidadas(objRenombrado[propiedad]);
    }

    return objRenombrado;
  } else return obj;
};

export const dateAll = free => {
  return free.getFullYear() + "-" + pad(free.getMonth() + 1) + "-" + pad(free.getDate());
};

export const fetchOperadores = async key => {
  const agency_id = +jsCookie.get("agency_id");

  const operadores = renombrarPropiedadesAnidadas(
    (
      await customFetch(
        `/operators/list?expand=profiles.letters.client,profiles.operator,paused_profiles.operator&agencyId=${
          isNaN(agency_id) ? 1 : agency_id
        }`,
      )
    ).data,
  );
  return operadores;
};

export function calcularEdad(fecha, hoy = new Date()) {
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  if (isNaN(edad)) return 0;
  return edad;
}

export const useCopyTextOnClick = (ref, texto) => {
  const CSSToInject = css`
    cursor: pointer;
    transition: transform 0.12s linear;

    :hover {
      transform: translateY(-6px);
    }

    :active {
      transform: translateY(3px);
    }
  `;

  useEffect(() => {
    const node = ref.current;

    if (!node) return;

    const copyText = () => {
      navigator.clipboard.writeText(texto);
    };
    node.addEventListener("click", copyText);

    return () => node.removeEventListener("click", copyText);
  }, [ref, texto]);

  return CSSToInject;
};

export const reducirCorazones2 = corazones => {
  const Amarillos = corazones / 100;
  const corazonesAmarillos = Math.floor(Amarillos);
  let Verdes = 0;
  let corazonesVerdes = 0;
  let Rojos = 0;
  let corazonesRojos = 0;

  if (corazonesAmarillos <= 16) {
    Verdes = (Amarillos - corazonesAmarillos) * 10;
    corazonesVerdes = Math.floor(Verdes);
    if (corazonesAmarillos + corazonesVerdes <= 16) {
      Rojos = (Verdes - corazonesVerdes) * 10;
      corazonesRojos = Math.floor(Rojos);
      if (corazonesAmarillos + corazonesVerdes + corazonesRojos > 16) {
        corazonesRojos = corazonesRojos - (corazonesAmarillos + corazonesVerdes + corazonesRojos - 16);
      }
    }
  }

  return [corazonesAmarillos, corazonesVerdes, corazonesRojos];
};

export const reducirCorazones = corazones => {
  let corazonesRojos = corazones;
  let corazonesVerdes = 0;
  let corazonesAmarillo = 0;

  if (corazonesRojos > 16) {
    corazonesVerdes = Math.floor(corazonesRojos / 10);
    if (corazonesVerdes > 16) {
      corazonesAmarillo = Math.floor(corazonesVerdes / 10);
      if (corazonesAmarillo > 16) {
        corazonesAmarillo = 16;
        corazonesVerdes = 0;
        corazonesRojos = 0;
      } else {
        corazonesVerdes = 16 - corazonesAmarillo;
        corazonesRojos = 0;
      }
    } else {
      corazonesRojos = 16 - corazonesVerdes;
    }
  }

  return [corazonesAmarillo, corazonesVerdes, corazonesRojos];
};

export const getFeedsUIData = feeds => {
  return feeds?.data.map(feed => {
    // Este objeto Date tiene todos los datos correctos menos la ubicacion
    // Toma la ubicacion del sistema (vzla en mi caso) en vez de UTC. No deberia dar ningun problema
    const fecha = new Date(feed.timestamp);
    const hora = fecha.getHours();

    let dia = fecha.getDate();
    dia = dia < 10 ? `0${dia}` : dia;

    let minuto = fecha.getMinutes();
    minuto = minuto < 10 ? `0${minuto}` : minuto;

    // Este objeto Date no sirve. `feed.timestamp` es una cadena de tiempo UTC
    // `new Date()` crea un objeto con tiempo local, asi que `badDate` vive 4 horas en el futuro
    const badDate = new Date(feed.timestamp);

    const feedUIData = {
      id: feed.id,
      commissionId: feed.commissionId,
      type: feed.service,
      clienteFoto: undefined,
      perfilFoto: feed.perfil?.foto,
      clienteId: feed.cliente?.amolatinaId,
      clienteNombre: feed.cliente?.nombre,
      perfilNombre: feed.perfil?.nombre,
      perfilId: feed.perfil?.id,
      corazones: feed.perfil?.corazones,
      coronas: feed.cliente?.coronas,
      dia,
      mes: mesDelYear(fecha.getMonth()),
      year: fecha.getFullYear(),
      hora: hora < 13 ? hora : hora - 12,
      minuto,
      meridian: hora < 12 ? "am" : "pm",
      operadorId: feed.operador?.id,
      operadorNombre: feed?.operadorDePausa?.nombre ?? feed?.operador?.nombre,
      operadorFoto: feed?.operadorDePausa?.foto ?? feed?.operador?.foto,
      prestado: !!feed?.operadorDePausa,
      operadorOriginalNombre: feed?.operador?.nombre,
      timestamp: Date.UTC(
        // Aqui se usa badDate para generar un objeto Date con el tiempo correcto
        badDate.getFullYear(),
        badDate.getMonth(),
        badDate.getDate(),
        badDate.getHours(),
        badDate.getMinutes(),
        badDate.getSeconds(),
        badDate.getMilliseconds(),
      ),
    };

    return feedUIData;
  });
};

/**
 * @param  {string} key
 * @param  {"admin"|"operador"|"supervisor"} role
 * @param  {number} pagina
 * @param  {string} tipo
 * @param  {string} sort
 * @param  {string} perfilId
 * @param  {{role:string,id:string[]}} usuariosEspecificos
 */
export const fetchFeeds = async (key, pagina, tipo, sort, perfilId, entidadSeleccionada, operadorId, agency_id) => {
  const feedsApiPoint = (() => {
    switch (entidadSeleccionada.entity) {
      case "all":
        return "list";
      case "user":
        return "personal-list";
      case "supervisor":
        return "supervisor";
      case "agency":
        return "agency";
      default:
        break;
    }
  })();

  let positiveFeeds = null;
  let service = sort !== "todos" ? sort : null;

  if (tipo === "bonus") positiveFeeds = true;
  else if (tipo === "writeoff") positiveFeeds = false;

  perfilId = perfilId === "todos" ? undefined : Number(perfilId);
  operadorId = operadorId === "todos" ? undefined : Number(operadorId);
  const agencyId = Number(agency_id);

  const feeds = await customFetch(`/activity/${feedsApiPoint}?page=${pagina}`, {
    method: "POST",
    body: JSON.stringify({
      positive: positiveFeeds,
      id: feedsApiPoint !== "supervisor" ? entidadSeleccionada?.id : null,
      ids: feedsApiPoint === "supervisor" ? entidadSeleccionada?.ids : null,
      service,
      profileId: perfilId,
      operatorId: operadorId,
      type: tipo,
      agencyId: agencyId,
    }),
    headers: { "content-type": "application/json" },
  });

  // feeds.data = renombrarPropiedadesAnidadas(feeds.data);
  return feeds;
};

// Manual bonus y writeoff tienen "manual" como id pero lo diferencio porque este enum mapea los nombres de los feeds
// para el texto de ContainerTag en los container de feeds.
// En fetchBonus se hace un paso adicional para contar con esto en el filtrado
export const feedsEnum = {
  ONLINE_CHAT: "dialogs.messages:intervals",
  CHAT_MESSAGE: "dialogs.messages:messages",
  RECEIVED_EMAIL: "dialogs.letters:send",
  SENT_EMAIL: "dialogs.letters:read",
  VIEWED_ATTACHMENT: "dialogs.attachments",
  VIDEO_STREAM: "dialogs.media",
  PROFILE_VIDEO: "paidresource:video",
  PRESENT: "present",
  LISTENED_MESSAGE: "listened-message",
  RECEIVED_MESSAGE: "received-message",
  MANUAL_BONUS: "manualbonus",
  GIFT: "cheers",
  VOICE_MESSAGE: "dialogs.attachments.audio",
  MISSED_INVITE: "dialogs.interval.added:missed",
  MISSED_ANSWER: "dialogs.interval.answer:missed",
  MISSED_MESSAGE: "",
  UNANSWERED_EMAIL: "dialogs.letter:missed",
  MISSED_EMAIL: "dialogs.letter:missed.hopelessly",
  MISSED_VIDEO_INVITE: "dialogs.media:missed",
  MISSED_PRESENT: "present:missed",
  MANUAL_WRITEOFF: "manualwriteoff",
  WRITEOFF_FINE: "writeoff",
  MINGLE_FINE: "mingle",
  OFFLINE_FINE: "offline",
  MISS_FINE: "miss",
  LETTER_FINE: "letter",
  REPORT_FINE: "report",
  MANUAL_FINE: "manual",
  SUPERBOT_LETTER: "super_bot_letter_deliveries",
  SUPERBOT_LINE: "super_bot_line_deliveries",
  SUPERBOT_AUDIO: "super_bot_audio_deliveries",
  SUPERBOT_PHOTO: "super_bot_photo_deliveries",
  SUPERBOT_VIDEO: "super_bot_video_deliveries",
  RECEIVED_INVITES: "receivedinvites",
  MISSED_INVITES: "missedinvites",
  SENT_INVITES_AUTO: "sentinvitesauto",
  SENT_INVITES_MANUAL: "sentinvitesmanual",
  CHAT_QUANTITY: "chatquantity",
  AVERAGE_CHAT_LENGTH: "averagechatlength",
  ACTIVE_DAYS: "activedays",
  ONLINE: "online",
  ONLINE_WITH_CAM: "onlinewithcam",
};

export const feedTypes = [
  { name: "Todos", value: "todos" },
  { name: "Bonus", value: "bonus" },
  { name: "Writeoff", value: "writeoff" },
  { name: "Multas", value: "multas" },
  { name: "Avisos", value: "alertas" },
  { name: "Cartas 24", value: "cartas24" },
  { name: "Cartas", value: "superbot-letters" },
  { name: "Lineas", value: "superbot-lines" },
  { name: "Fotos", value: "superbot-photos" },
  { name: "Videos", value: "superbot-videos" },
  { name: "Audios", value: "superbot-audios" },
];

export const bonusTypes = [
  { name: "Todos", value: "todos" },
  { name: "Online Chat", value: feedsEnum.ONLINE_CHAT },
  { name: "Chat Message", value: feedsEnum.CHAT_MESSAGE },
  { name: "Received Email", value: feedsEnum.RECEIVED_EMAIL },
  { name: "Sent Email", value: feedsEnum.SENT_EMAIL },
  { name: "Viewed Attachment", value: feedsEnum.VIEWED_ATTACHMENT },
  { name: "Video Stream", value: feedsEnum.VIDEO_STREAM },
  { name: "Profile Video", value: feedsEnum.PROFILE_VIDEO },
  { name: "Present", value: feedsEnum.PRESENT },
  { name: "Manual Bonus", value: feedsEnum.MANUAL_BONUS },
  { name: "Gift", value: feedsEnum.GIFT },
  { name: "Audio Escuchado", value: feedsEnum.LISTENED_MESSAGE },
  { name: "Audio Recibido", value: feedsEnum.RECEIVED_MESSAGE },
];

export const resumenService = [
  {
    service: "receivedinvites",
    name: "Recibidos",
  },
  {
    service: "missedinvites",
    name: "Perdidas",
  },
  {
    service: "sentinvitesauto",
    name: "Mingles",
  },
  {
    service: "sentinvitesmanual",
    name: "Manuales",
  },
  {
    service: "chatquantity",
    name: "Chats",
  },
  {
    service: "averagechatlength",
    name: "P/chats",
  },
  {
    service: "activedays",
    name: "Dias Activo",
  },
  {
    service: "online",
    name: "Online",
  },
  {
    service: "onlinewithcam",
    name: "Online con camara",
  },
];

export const resumeTypes = [
  { name: "Todos", value: "todos" },
  { name: "Invitaciones Recibidas", value: feedsEnum.RECEIVED_INVITES },
  { name: "Invitaciones Perdidas", value: feedsEnum.MISSED_INVITES },
  { name: "Mingles Enviados", value: feedsEnum.SENT_INVITES_AUTO },
  { name: "Invitaciones Manuales", value: feedsEnum.SENT_INVITES_MANUAL },
  { name: "Cantidad De Chats", value: feedsEnum.CHAT_QUANTITY },
  { name: "Promedio De Chats", value: feedsEnum.AVERAGE_CHAT_LENGTH },
  { name: "Dias Activos", value: feedsEnum.ACTIVE_DAYS },
  { name: "Online", value: feedsEnum.ONLINE },
  { name: "Online Con Camara", value: feedsEnum.ONLINE_WITH_CAM },
];

export const writeoffTypes = [
  { name: "Todos", value: "todos" },
  { name: "Missed Invite", value: feedsEnum.MISSED_INVITE },
  { name: "Missed Answer", value: feedsEnum.MISSED_ANSWER },
  { name: "Missed Message", value: feedsEnum.MISSED_MESSAGE },
  { name: "Unanswered Email", value: feedsEnum.UNANSWERED_EMAIL },
  { name: "Missed Email", value: feedsEnum.MISSED_EMAIL },
  { name: "Missed Video Invite", value: feedsEnum.MISSED_VIDEO_INVITE },
  { name: "Missed Present", value: feedsEnum.MISSED_PRESENT },
  { name: "Manual Writeoff", value: feedsEnum.MANUAL_WRITEOFF },
];

export const bonusYWriteoffTypes = [...bonusTypes, ...writeoffTypes];

export const useFeedsNotifications = (feeds, pagina, tipo, sort, perfilId, isFetching, usuarioEspecifico) => {
  const ultimoFeedId = useRef();

  useEffect(() => {
    ultimoFeedId.current = null;
  }, [tipo, sort, perfilId, usuarioEspecifico?.role, usuarioEspecifico?.id]);

  useEffect(() => {
    if (pagina === 1) {
      if (ultimoFeedId.current && ultimoFeedId.current !== feeds.data[0].id) {
        // Indice del ultimo feed que se trackeo
        const index = feeds.data.findIndex(feed => feed.id === ultimoFeedId.current);

        for (let i = index - 1; i >= 0; i--) {
          const feed = feeds.data[i];
          if (feed.commissionId <= 0) {
            mandarNotificacion("Multa", "writeoff");
            continue;
          }

          const cliente = feed?.cliente.nombre;
          const perfil = feed?.perfil.nombre;
          const service = bonusYWriteoffTypes?.find(t => t.value === feed.service).name;
          const points = feed.points;

          let mensaje = `${cliente} y ${perfil} | ${service}`;
          let icono = points > 0 ? "bonus" : "writeoff";

          mandarNotificacion(mensaje, icono);
        }
      }

      // Evita que se dispare la notificacion si aparece un feed nuevo inmediatamente despues de hacer cambio de filtro
      // cuando el cambio de filtro genera un fetch
      if (!isFetching) ultimoFeedId.current = feeds?.data[0]?.id;
    }
  }, [feeds, isFetching, pagina]);
};

export const useFeedsFilter = setPagina => {
  const [showFilter, setShowFilter] = useState(false);
  const [sort, setSort] = useState("todos");
  const [tipo, setTipo] = useState("todos");
  const [perfilId, setPerfilId] = useState("todos");
  const [operadorId, setOperadorId] = useState("todos");
  const perfiles = queryCache.getQueryData(["perfiles"]);

  const role = jsCookie.get("role");
  const id = jsCookie.get("id");
  const { data: operadores } = useQuery(
    role === "admin"
      ? ["operadores"]
      : role === "supervisor" || role === "shift"
      ? ["operadores-supervisor", id]
      : null,
    role === "admin" ? fetchOperadores : role === "supervisor" || role === "shift" ? fetchOperadoresDeSupervisor : null,
  );

  const setFeedsPaginaTo1 = () => setPagina(1);

  useEffect(() => setSort("todos"), [tipo]);

  let containterTagTxt = "Toda actividad";

  if (tipo === "bonus") {
    containterTagTxt = "Bonus";
    if (sort !== "todos") containterTagTxt += ": " + bonusTypes?.find(({ value }) => value === sort)?.name;
  } else if (tipo === "writeoff") {
    containterTagTxt = "Writeoff";
    if (sort !== "todos") containterTagTxt += ": " + writeoffTypes?.find(({ value }) => value === sort)?.name;
  } else if (tipo === "multas") {
    containterTagTxt = "Multas";
  } else if (tipo === "alertas") {
    containterTagTxt = "Alertas";
  } else if (tipo === "cartas24") {
    containterTagTxt = "Cartas 24";
  }

  if (operadorId !== "todos" && role !== "operator") {
    const operadorNombre = operadores?.find(operador => operador.id === Number(operadorId)).nombre;
    containterTagTxt += ` | Operador: ${operadorNombre}`;
  }

  if (perfilId !== "todos") {
    const perfilNombre = perfiles?.find(perfil => perfil.id === Number(perfilId)).nombre;
    containterTagTxt += ` | Perfil: ${perfilNombre}`;
  }

  return [
    showFilter,
    setShowFilter,
    sort,
    setSort,
    tipo,
    setTipo,
    setFeedsPaginaTo1,
    containterTagTxt,
    operadorId,
    setOperadorId,
    perfilId,
    setPerfilId,
  ];
};

export const useFeeds = entidadSeleccionada => {
  const [pagina, setPagina] = useState(1);
  const agency_id = +jsCookie.get("agency_id");

  const [
    showFilter,
    setShowFilter,
    sort,
    setSort,
    tipo,
    setTipo,
    setFeedsPaginaTo1,
    containterTagTxt,
    operadorId,
    setOperadorId,
    perfilId,
    setPerfilId,
  ] = useFeedsFilter(setPagina);

  const { latestData: feeds, resolvedData: pageCountData } = usePaginatedQuery(
    ["feeds", pagina, tipo, sort, perfilId, entidadSeleccionada, operadorId, agency_id],
    fetchFeeds,
    {
      // refetchInterval: process.env.NODE_ENV === "production" ? 5000 : 5000,
      staleTime: pagina === 1 ? 0 : Infinity,
      refetchInterval: pagina === 1 ? 1000 * 60 * 10 : 99999999999,
    },
  );

  const feedsUIData = feeds?.data ?? [];

  useEffect(() => {
    queryCache.invalidateQueries(["feeds"]);
  }, [pagina]);

  return [
    pagina,
    setPagina,
    showFilter,
    setShowFilter,
    sort,
    setSort,
    tipo,
    setTipo,
    setFeedsPaginaTo1,
    containterTagTxt,
    pageCountData,
    renombrarPropiedadesAnidadas(feedsUIData),
    operadorId,
    setOperadorId,
    perfilId,
    setPerfilId,
  ];
};
export const refetchPerfil = async perfilId => {
  const perfilActualizado = renombrarPropiedadesAnidadas(
    (await customFetch(`/profiles/info?id=${perfilId}&expand=operator,pause_operator,dia_summary,mes_summary`))?.data,
  );

  await queryCache.setQueryData(["perfiles"], oldPerfiles => {
    if (!oldPerfiles) return;

    const i = oldPerfiles.findIndex(p => p.id === perfilId);
    oldPerfiles[i] = perfilActualizado;
    return oldPerfiles;
  });
};
export const refetchOperador = async operadorId => {
  const operadorActualizado = renombrarPropiedadesAnidadas(
    (
      await customFetch(
        `/operators/info?id=${operadorId}&expand=profiles.letters.client,profiles.operator,paused_profiles.operator`,
      )
    ).data,
  );

  await queryCache.setQueryData(["operadores"], oldOperadores => {
    if (!oldOperadores) return;

    const i = oldOperadores.findIndex(p => p.id === operadorId);
    oldOperadores[i] = operadorActualizado;
    return oldOperadores;
  });
};

export const fetchPerfiles = async key => {
  const agency_id = +jsCookie.get("agency_id");

  const perfiles = renombrarPropiedadesAnidadas(
    (await customFetch(`/profiles/list?expand=operator,pause_operator,dia_summary,mes_summary&agencyId=${agency_id}`))
      .data,
  );
  return perfiles;
};

export const fetchInfoClients = async (key, Id) => {
  return customFetch(`/clients/info-client?id=${Id}`);
};

export const fetchOperador = async (key, id, type) => {
  if (type === "profile" || type === "client") return null;
  const operador = renombrarPropiedadesAnidadas((await customFetch(`/operators/info?id=${id}`)).data);
  return operador;
};

export const fetchSupervisores = async () => {
  return renombrarPropiedadesAnidadas((await customFetch("/supervisors/list")).data);
};

export const FlagPut = ({ country }) => {
  if (country === "all" || !country)
    return (
      <div tw="flex items-center flex-col ">
        <GlobeSvg
          tw="xl:w-[0.8rem] lg:w-[0.6rem] md:w-[0.5rem] h-auto"
          css="@media (min-width: 2560px) { width: 1.5rem !important; }"
        />
      </div>
    );
  return (
    <div tw="flex flex-col items-center ">
      <img
        tw="xl:w-[0.8rem] lg:w-[0.6rem] md:w-[0.5rem] h-auto"
        css="@media (min-width: 2560px) { width: 1.5rem !important; }"
        alt="Flag"
        src={`https://flagsapi.com/${country}/flat/64.png`}
      />
    </div>
  );
};

export const fetchCountrySalary = async (key, AgencyId = +jsCookie.get("agency_id")) => {
  return await customFetch(`/agency/country-salary?agencyId=${AgencyId}`);
};

export function sumarDias(fecha, dias) {
  fecha.setUTCDate(fecha.getUTCDate() + dias);
  return fecha;
}

export const DivCheck = styled.div`
  input[type="checkbox"] {
    @media (min-width: 2560px) {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px !important;
      border: 2px solid #4c4c3f !important;
    }
    ${tw`
      xl:w-[12px]
      xl:h-[12px]
      lg:w-[8px]
      lg:h-[8px]
      md:w-[6px]
      md:h-[6px]
      w-[4px]
      h-[4px]
      cursor-pointer
      2xl:rounded-sm
      xl:rounded-sm
      lg:rounded-sm
      md:rounded-sm
      md:mr-[2px]
      md:border[1px solid #4c4c3f]
    `}
    appearance: none;
  }

  input:checked {
    @media (min-width: 2560px) {
      border: 6px solid #4c4c3f !important;
    }
    ${tw`
      2xl:border[7px solid #4c4c3f]
      xl:border[6px solid #4c4c3f]
      lg:border[4px solid #4c4c3f]
      md:border[3px solid #4c4c3f]
      border[1px solid #4c4c3f]
    `}
  }
`;

export const fetchSupervisor = async (key, id) => {
  let [supervisor, feedsSummary] = await Promise.all([
    customFetch(`/supervisors/info?id=${id}&expand=operators.profiles.letters.client`),
    customFetch(`/feeds/supervisor-operators-summary`),
  ]);

  const perfilesAsociados = [];
  supervisor.data.operators?.forEach(operator => {
    if (operator.profiles) perfilesAsociados.push(...operator.profiles);
  });

  let cartas = perfilesAsociados
    .map(perfil => {
      return perfil.letters.map(letter => ({
        ...letter,
        perfil,
        cliente: letter.client,
      }));
    })
    .flat();

  cartas = cartas.map(({ id, read_timestamp, replied, timestamp, cliente, perfil }) => {
    return {
      id,
      perfilNombre: perfil.name,
      perfilFoto: perfil.photo,
      corazones: perfil.hearts,
      clienteNombre: cliente.name,
      clienteFoto: cliente?.photo, // No la tienen todavia pero si se implementa deberia funcionar sin problema
      coronas: cliente.crowns,
      clienteAmolatinaId: cliente.amolatina_id,
      tiempoLlegada: Math.floor(timestamp / 1000),
      leida: !!read_timestamp,
      respondida: replied,
    };
  });

  supervisor = renombrarPropiedadesAnidadas(supervisor.data);

  return { supervisor, feedsSummary, cartas };
};

export const fetchOperadoresDeSupervisor = async (key, id) => {
  return renombrarPropiedadesAnidadas(
    (await customFetch(`/supervisors/info?id=${id}&expand=operators`)).data.operators,
  );
};

export const fetchOperadorSummary = async (key, id, tiempo) => {
  return renombrarPropiedadesAnidadas(await customFetch(`/feeds/operator-summary?id=${id}&tiempo=${tiempo}`));
};

export const fetchThumbnails = (key, ...mediaIds) => {
  return Promise.all(mediaIds.map(mediaId => customFetch(`/drive-files/file-info?fileId=${mediaId}`)));
};

export const fetchDriveFiles = async (key, perfilId, type, folderId, client) => {
  return await customFetch(
    `/drive-files/explore?profileId=${perfilId}&type=${type}&folderId=${folderId ?? ""}&client=${client}`,
  );
};

export const fetchDriveFilesInfo = async (key, fileIds) => {
  if (!fileIds || fileIds.length === 0) return [];

  const fileInfos = [];
  for (const fileId of fileIds) {
    fileInfos.push(customFetch("/drive-files/file-info?fileId=" + fileId));
  }

  return (await Promise.allSettled(fileInfos)).map(({ value }) => value);
};

export const fetchAgencias = async key => {
  return await customFetch("/agency/list");
};
export const fetchMetas = async (key, operadorId, time, timespan, completed, shift, agencyId, country) => {
  if (timespan !== "day" && timespan !== "month") {
    operadorId = undefined;
    time = undefined;
    timespan = undefined;
    completed = undefined;
  }
  const completedAdd = completed !== undefined ? "&completed=" + completed : "";
  const operadorIdAdd = operadorId !== undefined ? "&operatorId=" + operadorId : "";
  const timeAdd = time === "mes-pasado" ? "&pastMonth=true" : "";
  const timespanAdd = timespan !== undefined ? "&timespan=" + timespan : "";
  const shiftAdd = shift !== undefined ? "&shift=" + shift : "";
  const agencyIdAdd = agencyId !== undefined ? "&agencyId=" + agencyId : "";
  const countryAdd = country !== undefined ? "&country=" + country : "";

  return await customFetch(
    `/goals/list?${completedAdd}${operadorIdAdd}${timeAdd}${timespanAdd}${shiftAdd}${agencyIdAdd}${countryAdd}`,
  );
};

/**
 * @param  {any} response
 * @param  {"toma" | "liberacion" | "pausa" | "reanudacion"} tipo
 */
export const errorPerfilesToast = (response, tipo) => {
  if (response.status === 400) {
    new Audio(errorSound).play();

    if (tipo === "toma") {
      if (response.message === "mingle") toast.error("No puedes tomar un perfil sin mingle");
      if (response.message === "offline") toast.error("No puedes tomar un perfil que no está en linea");
      if (response.message === "letter") toast.error("No puedes tomar un perfil con cartas sin responder");
    } else if (tipo === "liberacion") {
      if (response.message === "mingle") toast.error("No puedes liberar un perfil sin mingle");
      if (response.message === "offline") toast.error("No puedes liberar un perfil que no está en linea");
      if (response.message === "letter") toast.error("No puedes liberar un perfil con cartas sin responder");
    } else if (tipo === "pausa") {
      if (response.message === "mingle") toast.error("No puedes pausar si un perfil no tiene mingle");
      if (response.message === "offline") toast.error("No puedes pausar si un perfil no está en linea");
      if (response.message === "letter") toast.error("No puedes pausar si un perfil tiene cartas sin responder");
      if (response.message === "prestamo") toast.error("No puedes pausar si uno de tus perfiles es prestado");
    } else {
      if (response.message === "mingle") toast.error("No puedes reanudar si un perfil no tiene mingle");
      if (response.message === "offline") toast.error("No puedes reanudar si un perfil no está en linea");
      if (response.message === "letter") toast.error("No puedes reanudar si un perfil tiene cartas sin responder");
    }
  }
};

export const alertToast = (type, data) => {
  new Audio(errorSound).play();

  if (type === "report") {
    toast.info(data);
  }
};

// export const fetchTags = async () => {
//   return await customFetch("/clients/tags");
// };

export const coverIds = [
  "97cfe3",
  "18315a",
  "1f6656",
  "2e2528",
  "6a205f",
  "b0dad8",
  "b7dece",
  "b82025",
  "bbdef5",
  "bc1f46",
  "c9a574",
  "d1ae70",
  "d86227",
  "e3e2d5",
  "e8eae9",
  "ebc8a6",
  "ece4cd",
  "efded2",
  "f4dcc4",
  "f4e7d7",
  "f6ece0",
  "fcfddf",
  "fff2d3",
  "fff4e5",
  "fffef4",
];

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const idiomas = [
  { code: "en", name: "inglés" },
  { code: "th", name: "tailandés" },
  { code: "af", name: "afrikáans" },
  { code: "es", name: "español" },
  { code: "id", name: "indonesio" },
  { code: "sq", name: "albanés" },
  { code: "de", name: "alemán" },
  { code: "am", name: "amhárico" },
  { code: "ar", name: "árabe" },
  { code: "hy", name: "armenio" },
  { code: "az", name: "azerí" },
  { code: "bn", name: "bengalí" },
  { code: "be", name: "bielorruso" },
  { code: "my", name: "birmano" },
  { code: "bs", name: "bosnio" },
  { code: "bg", name: "búlgaro" },
  { code: "km", name: "camboyano" },
  { code: "kn", name: "canarés" },
  { code: "ca", name: "catalán" },
  { code: "ceb", name: "cebuano" },
  { code: "cs", name: "checo" },
  { code: "ny", name: "chichewa" },
  { code: "zh-CN", name: "chino (simplificado)" },
  { code: "zh-TW", name: "chino (tradicional)" },
  { code: "si", name: "cingalés" },
  { code: "ko", name: "coreano" },
  { code: "co", name: "corso" },
  { code: "ht", name: "criollo haitiano" },
  { code: "hr", name: "croata" },
  { code: "da", name: "danés" },
  { code: "sk", name: "eslovaco" },
  { code: "sl", name: "esloveno" },
  { code: "eo", name: "esperanto" },
  { code: "et", name: "estonio" },
  { code: "eu", name: "euskera" },
  { code: "fi", name: "finlandés" },
  { code: "fr", name: "francés" },
  { code: "fy", name: "frisio" },
  { code: "gd", name: "gaélico escocés" },
  { code: "cy", name: "galés" },
  { code: "gl", name: "gallego" },
  { code: "ka", name: "georgiano" },
  { code: "el", name: "griego" },
  { code: "gu", name: "gujarati" },
  { code: "ha", name: "hausa" },
  { code: "haw", name: "hawaiano" },
  { code: "iw", name: "hebreo" },
  { code: "hi", name: "hindi" },
  { code: "hmn", name: "hmong" },
  { code: "hu", name: "húngaro" },
  { code: "ig", name: "igbo" },
  { code: "ga", name: "irlandés" },
  { code: "is", name: "islandés" },
  { code: "it", name: "italiano" },
  { code: "ja", name: "japonés" },
  { code: "jw", name: "javanés" },
  { code: "kk", name: "kazajo" },
  { code: "rw", name: "kinyarwanda" },
  { code: "ky", name: "kirguís" },
  { code: "ku", name: "kurdo" },
  { code: "lo", name: "lao" },
  { code: "la", name: "latín" },
  { code: "lv", name: "letón" },
  { code: "lt", name: "lituano" },
  { code: "lb", name: "luxemburgués" },
  { code: "mk", name: "macedonio" },
  { code: "ml", name: "malayalam" },
  { code: "ms", name: "malayo" },
  { code: "mg", name: "malgache" },
  { code: "mt", name: "maltés" },
  { code: "mi", name: "maorí" },
  { code: "mr", name: "maratí" },
  { code: "mn", name: "mongol" },
  { code: "nl", name: "neerlandés" },
  { code: "ne", name: "nepalí" },
  { code: "no", name: "noruego" },
  { code: "or", name: "oriya" },
  { code: "pa", name: "panyabí" },
  { code: "ps", name: "pastún" },
  { code: "fa", name: "persa" },
  { code: "pl", name: "polaco" },
  { code: "pt", name: "portugués" },
  { code: "ro", name: "rumano" },
  { code: "ru", name: "ruso" },
  { code: "sm", name: "samoano" },
  { code: "sr", name: "serbio" },
  { code: "st", name: "sesoto" },
  { code: "sn", name: "shona" },
  { code: "sd", name: "sindhi" },
  { code: "so", name: "somalí" },
  { code: "sw", name: "suajili" },
  { code: "sv", name: "sueco" },
  { code: "su", name: "sundanés" },
  { code: "tl", name: "tagalo" },
  { code: "ta", name: "tamil" },
  { code: "tt", name: "tártaro" },
  { code: "tg", name: "tayiko" },
  { code: "te", name: "telugu" },
  { code: "tr", name: "turco" },
  { code: "tk", name: "turkmeno" },
  { code: "uk", name: "ucraniano" },
  { code: "ug", name: "uigur" },
  { code: "ur", name: "urdu" },
  { code: "uz", name: "uzbeco" },
  { code: "vi", name: "vietnamita" },
  { code: "xh", name: "xhosa" },
  { code: "yi", name: "yidis" },
  { code: "yo", name: "yoruba" },
  { code: "zu", name: "zulú" },
  { code: "he", name: "hebreo" },
  { code: "zh", name: "chino (simplificado)" },
];

export const CalcDay = day => {
  return isNaN(Math.round((new Date().getTime() - new Date(day).getTime()) / (1000 * 60 * 60 * 24)))
    ? 0
    : Math.round((new Date().getTime() - new Date(day).getTime()) / (1000 * 60 * 60 * 24));
};

export const paises = [
  {
    name_en: "Afghanistan",
    name_es: "Afganistán",
    dial_code: "+93",
    code: "AF",
  },
  {
    name_en: "Albania",
    name_es: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name_en: "Algeria",
    name_es: "Argelia",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name_en: "AmericanSamoa",
    name_es: "Samoa Americana",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name_en: "Andorra",
    name_es: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name_en: "Angola",
    name_es: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name_en: "Anguilla",
    name_es: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name_en: "Antarctica",
    name_es: "Antártida",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name_en: "Antigua and Barbuda",
    name_es: "Antigua y Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name_en: "Argentina",
    name_es: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name_en: "Armenia",
    name_es: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name_en: "Aruba",
    name_es: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name_en: "Australia",
    name_es: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name_en: "Austria",
    name_es: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name_en: "Azerbaijan",
    name_es: "Azerbaiyán",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name_en: "Bahamas",
    name_es: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name_en: "Bahrain",
    name_es: "Baréin",
    dial_code: "+973",
    code: "BH",
  },
  {
    name_en: "Bangladesh",
    name_es: "Banglades",
    dial_code: "+880",
    code: "BD",
  },
  {
    name_en: "Barbados",
    name_es: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name_en: "Belarus",
    name_es: "Bielorrusia",
    dial_code: "+375",
    code: "BY",
  },
  {
    name_en: "Belgium",
    name_es: "Bélgica",
    dial_code: "+32",
    code: "BE",
  },
  {
    name_en: "Belize",
    name_es: "Belice",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name_en: "Benin",
    name_es: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name_en: "Bermuda",
    name_es: "Bermudas",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name_en: "Bhutan",
    name_es: "Butan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name_en: "Bolivia",
    name_es: "Bolivia",
    dial_code: "+591",
    code: "BO",
  },
  {
    name_en: "Bosnia and Herzegovina",
    name_es: "Bosnia-Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name_en: "Botswana",
    name_es: "Botsuana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name_en: "Brazil",
    name_es: "Brasil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name_en: "British Indian Ocean Territory",
    name_es: "Territorio Británico del Océano Índico",
    dial_code: "+246",
    code: "IO",
  },
  {
    name_en: "Brunei Darussalam",
    name_es: "Brunei",
    dial_code: "+673",
    code: "BN",
  },
  {
    name_en: "Bulgaria",
    name_es: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name_en: "Burkina Faso",
    name_es: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name_en: "Burundi",
    name_es: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name_en: "Cambodia",
    name_es: "Camboya",
    dial_code: "+855",
    code: "KH",
  },
  {
    name_en: "Cameroon",
    name_es: "Camerún",
    dial_code: "+237",
    code: "CM",
  },
  {
    name_en: "Canada",
    name_es: "Canadá",
    dial_code: "+1",
    code: "CA",
  },
  {
    name_en: "Cape Verde",
    name_es: "Cabo Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name_en: "Cayman Islands",
    name_es: "Islas Caimán",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name_en: "Central African Republic",
    name_es: "República Centroafricana",
    dial_code: "+236",
    code: "CF",
  },
  {
    name_en: "Chad",
    name_es: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name_en: "Chile",
    name_es: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name_en: "China",
    name_es: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name_en: "Christmas Island",
    name_es: "Isla de Navidad",
    dial_code: "+61",
    code: "CX",
  },
  {
    name_en: "Cocos (Keeling) Islands",
    name_es: "Islas Cocos",
    dial_code: "+61",
    code: "CC",
  },
  {
    name_en: "Colombia",
    name_es: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name_en: "Comoros",
    name_es: "Comoras",
    dial_code: "+269",
    code: "KM",
  },
  {
    name_en: "Congo",
    name_es: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name_en: "Congo, The Democratic Republic of the",
    name_es: "República Democrática del Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name_en: "Cook Islands",
    name_es: "Islas Cook",
    dial_code: "+682",
    code: "CK",
  },
  {
    name_en: "Costa Rica",
    name_es: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name_en: "Cote d'Ivoire",
    name_es: "Costa de Marfil",
    dial_code: "+225",
    code: "CI",
  },
  {
    name_en: "Croatia",
    name_es: "Croacia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name_en: "Cuba",
    name_es: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name_en: "Cyprus",
    name_es: "Chipre",
    dial_code: "+537",
    code: "CY",
  },
  {
    name_en: "Czechia",
    name_es: "Chequia",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name_en: "Denmark",
    name_es: "Dinamarca",
    dial_code: "+45",
    code: "DK",
  },
  {
    name_en: "Djibouti",
    name_es: "Yibuti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name_en: "Dominica",
    name_es: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name_en: "Dominican Republic",
    name_es: "República Dominicana",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name_en: "Ecuador",
    name_es: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name_en: "Egypt",
    name_es: "Egipto",
    dial_code: "+20",
    code: "EG",
  },
  {
    name_en: "El Salvador",
    name_es: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name_en: "Equatorial Guinea",
    name_es: "Guinea Ecuatorial",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name_en: "Eritrea",
    name_es: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name_en: "Estonia",
    name_es: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name_en: "Ethiopia",
    name_es: "Etiopía",
    dial_code: "+251",
    code: "ET",
  },
  {
    name_en: "Falkland Islands (Malvinas)",
    name_es: "Islas Malvinas",
    dial_code: "+500",
    code: "FK",
  },
  {
    name_en: "Faroe Islands",
    name_es: "Islas Feroe",
    dial_code: "+298",
    code: "FO",
  },
  {
    name_en: "Fiji",
    name_es: "Fiyi",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name_en: "Finland",
    name_es: "Finlandia",
    dial_code: "+358",
    code: "FI",
  },
  {
    name_en: "France",
    name_es: "Francia",
    dial_code: "+33",
    code: "FR",
  },
  {
    name_en: "French Guiana",
    name_es: "Guayana Francesa",
    dial_code: "+594",
    code: "GF",
  },
  {
    name_en: "French Polynesia",
    name_es: "Polinesia Francesa",
    dial_code: "+689",
    code: "PF",
  },
  {
    name_en: "Gabon",
    name_es: "Gabón",
    dial_code: "+241",
    code: "GA",
  },
  {
    name_en: "Gambia",
    name_es: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name_en: "Georgia",
    name_es: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name_en: "Germany",
    name_es: "Alemania",
    dial_code: "+49",
    code: "DE",
  },
  {
    name_en: "Ghana",
    name_es: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name_en: "Gibraltar",
    name_es: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name_en: "Greece",
    name_es: "Grecia",
    dial_code: "+30",
    code: "GR",
  },
  {
    name_en: "Greenland",
    name_es: "Groenlandia",
    dial_code: "+299",
    code: "GL",
  },
  {
    name_en: "Grenada",
    name_es: "Granada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name_en: "Guadeloupe",
    name_es: "Guadalupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name_en: "Guam",
    name_es: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name_en: "Guatemala",
    name_es: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name_en: "Guernsey",
    name_es: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name_en: "Guinea",
    name_es: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name_en: "Guinea-Bissau",
    name_es: "Guinea-Bisau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name_en: "Guyana",
    name_es: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name_en: "Haiti",
    name_es: "Haití",
    dial_code: "+509",
    code: "HT",
  },
  {
    name_en: "Holy See (Vatican City State)",
    name_es: "Ciudad del Vaticano",
    dial_code: "+379",
    code: "VA",
  },
  {
    name_en: "Honduras",
    name_es: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name_en: "Hong Kong",
    name_es: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name_en: "Hungary",
    name_es: "Hungría",
    dial_code: "+36",
    code: "HU",
  },
  {
    name_en: "Iceland",
    name_es: "Islandia",
    dial_code: "+354",
    code: "IS",
  },
  {
    name_en: "India",
    name_es: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name_en: "Indonesia",
    name_es: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name_en: "Iran, Islamic Republic of",
    name_es: "Irán",
    dial_code: "+98",
    code: "IR",
  },
  {
    name_en: "Iraq",
    name_es: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name_en: "Ireland",
    name_es: "Irlanda",
    dial_code: "+353",
    code: "IE",
  },
  {
    name_en: "Isle of Man",
    name_es: "Isla de Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name_en: "Israel",
    name_es: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name_en: "Italy",
    name_es: "Italia",
    dial_code: "+39",
    code: "IT",
  },
  {
    name_en: "Jamaica",
    name_es: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name_en: "Japan",
    name_es: "Japón",
    dial_code: "+81",
    code: "JP",
  },
  {
    name_en: "Jersey",
    name_es: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name_en: "Jordan",
    name_es: "Jordania",
    dial_code: "+962",
    code: "JO",
  },
  {
    name_en: "Kazakhstan",
    name_es: "Kazajistán",
    dial_code: "+7",
    code: "KZ",
  },
  {
    name_en: "Kenya",
    name_es: "Kenia",
    dial_code: "+254",
    code: "KE",
  },
  {
    name_en: "Kiribati",
    name_es: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name_en: "Korea, Democratic People's Republic of",
    name_es: "Corea del Norte",
    dial_code: "+850",
    code: "KP",
  },
  {
    name_en: "Korea, Republic of",
    name_es: "Corea del Sur",
    dial_code: "+82",
    code: "KR",
  },
  {
    name_en: "Kosovo",
    name_es: "Kosovo",
    dial_code: "+383",
    code: "XK",
  },
  {
    name_en: "Kuwait",
    name_es: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name_en: "Kyrgyzstan",
    name_es: "Kirguistán",
    dial_code: "+996",
    code: "KG",
  },
  {
    name_en: "Lao People's Democratic Republic",
    name_es: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name_en: "Latvia",
    name_es: "Letonia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name_en: "Lebanon",
    name_es: "Líbano",
    dial_code: "+961",
    code: "LB",
  },
  {
    name_en: "Lesotho",
    name_es: "Lesoto",
    dial_code: "+266",
    code: "LS",
  },
  {
    name_en: "Liberia",
    name_es: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name_en: "Libyan Arab Jamahiriya",
    name_es: "Libia",
    dial_code: "+218",
    code: "LY",
  },
  {
    name_en: "Liechtenstein",
    name_es: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name_en: "Lithuania",
    name_es: "Lituania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name_en: "Luxembourg",
    name_es: "Luxemburgo",
    dial_code: "+352",
    code: "LU",
  },
  {
    name_en: "Macao",
    name_es: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name_en: "Macedonia, The Former Yugoslav Republic of",
    name_es: "República de Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name_en: "Madagascar",
    name_es: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name_en: "Malawi",
    name_es: "Malaui",
    dial_code: "+265",
    code: "MW",
  },
  {
    name_en: "Malaysia",
    name_es: "Malasia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name_en: "Maldives",
    name_es: "Maldivas",
    dial_code: "+960",
    code: "MV",
  },
  {
    name_en: "Mali",
    name_es: "Malí",
    dial_code: "+223",
    code: "ML",
  },
  {
    name_en: "Malta",
    name_es: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name_en: "Marshall Islands",
    name_es: "Islas Marshall",
    dial_code: "+692",
    code: "MH",
  },
  {
    name_en: "Martinique",
    name_es: "Martinica",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name_en: "Mauritania",
    name_es: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name_en: "Mauritius",
    name_es: "Mauricio",
    dial_code: "+230",
    code: "MU",
  },
  {
    name_en: "Mayotte",
    name_es: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name_en: "Mexico",
    name_es: "México",
    dial_code: "+52",
    code: "MX",
  },
  {
    name_en: "Micronesia, Federated States of",
    name_es: "Estados Federados de Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name_en: "Moldova, Republic of",
    name_es: "Moldavia",
    dial_code: "+373",
    code: "MD",
  },
  {
    name_en: "Monaco",
    name_es: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name_en: "Mongolia",
    name_es: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name_en: "Montenegro",
    name_es: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name_en: "Montserrat",
    name_es: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name_en: "Morocco",
    name_es: "Marruecos",
    dial_code: "+212",
    code: "MA",
  },
  {
    name_en: "Mozambique",
    name_es: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name_en: "Myanmar",
    name_es: "Birmania",
    dial_code: "+95",
    code: "MM",
  },
  {
    name_en: "Namibia",
    name_es: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name_en: "Nauru",
    name_es: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name_en: "Nepal",
    name_es: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name_en: "Netherlands",
    name_es: "Holanda",
    dial_code: "+31",
    code: "NL",
  },
  {
    name_en: "Netherlands Antilles",
    name_es: "Antillas Holandesas",
    dial_code: "+599",
    code: "AN",
  },
  {
    name_en: "New Caledonia",
    name_es: "Nueva Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name_en: "New Zealand",
    name_es: "Nueva Zelanda",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name_en: "Nicaragua",
    name_es: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name_en: "Niger",
    name_es: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name_en: "Nigeria",
    name_es: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name_en: "Niue",
    name_es: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name_en: "NorfolkIsland",
    name_es: "IslaNorfolk",
    dial_code: "+672",
    code: "NF",
  },
  {
    name_en: "NorthernMarianaIslands",
    name_es: "IslasMarianasdelNorte",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name_en: "Norway",
    name_es: "Noruega",
    dial_code: "+47",
    code: "NO",
  },
  {
    name_en: "Oman",
    name_es: "Omán",
    dial_code: "+968",
    code: "OM",
  },
  {
    name_en: "Pakistan",
    name_es: "Pakistán",
    dial_code: "+92",
    code: "PK",
  },
  {
    name_en: "Palau",
    name_es: "Palaos",
    dial_code: "+680",
    code: "PW",
  },
  {
    name_en: "Panama",
    name_es: "Panamá",
    dial_code: "+507",
    code: "PA",
  },
  {
    name_en: "Papua New Guinea",
    name_es: "Papúa Nueva Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name_en: "Paraguay",
    name_es: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name_en: "Peru",
    name_es: "Perú",
    dial_code: "+51",
    code: "PE",
  },
  {
    name_en: "Philippines",
    name_es: "Filipinas",
    dial_code: "+63",
    code: "PH",
  },
  {
    name_en: "Pitcairn",
    name_es: "Islas Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name_en: "Poland",
    name_es: "Polonia",
    dial_code: "+48",
    code: "PL",
  },
  {
    name_en: "Portugal",
    name_es: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name_en: "Puerto Rico",
    name_es: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name_en: "Qatar",
    name_es: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name_en: "Romania",
    name_es: "Rumania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name_en: "Russia",
    name_es: "Rusia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name_en: "Rwanda",
    name_es: "Ruanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name_en: "Réunion",
    name_es: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name_en: "Saint Barthélemy",
    name_es: "San Bartolome",
    dial_code: "+590",
    code: "BL",
  },
  {
    name_en: "Saint Helena, Ascension and Tristan Da Cunha",
    name_es: "Santa Elena, Ascensión y Tristán de Acuña",
    dial_code: "+290",
    code: "SH",
  },
  {
    name_en: "Saint Kitts and Nevis",
    name_es: "San Cristóbal y Nieves",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name_en: "Saint Lucia",
    name_es: "Santa Lucía",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name_en: "Saint Martin",
    name_es: "Isla de San Martín",
    dial_code: "+590",
    code: "MF",
  },
  {
    name_en: "Saint Pierre and Miquelon",
    name_es: "San Pedro y Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name_en: "Saint Vincent and the Grenadines",
    name_es: "San Vicente y las Granadinas",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name_en: "Samoa",
    name_es: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name_en: "San Marino",
    name_es: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name_en: "Sao Tome and Principe",
    name_es: " Santo Tomé y Príncipe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name_en: "Saudi Arabia",
    name_es: "Arabia Saudita",
    dial_code: "+966",
    code: "SA",
  },
  {
    name_en: "Senegal",
    name_es: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name_en: "Serbia",
    name_es: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name_en: "Seychelles",
    name_es: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name_en: "Sierra Leone",
    name_es: "Sierra Leona",
    dial_code: "+232",
    code: "SL",
  },
  {
    name_en: "Singapore",
    name_es: "Singapur",
    dial_code: "+65",
    code: "SG",
  },
  {
    name_en: "Slovakia",
    name_es: "Eslovaquia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name_en: "Slovenia",
    name_es: "Eslovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name_en: "Solomon Islands",
    name_es: "Islas Salomón",
    dial_code: "+677",
    code: "SB",
  },
  {
    name_en: "Somalia",
    name_es: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name_en: "South Africa",
    name_es: "Sudáfrica",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name_en: "South Sudan",
    name_es: "Sudán del Sur",
    dial_code: "+211",
    code: "SS",
  },
  {
    name_en: "Spain",
    name_es: "España",
    dial_code: "+34",
    code: "ES",
  },
  {
    name_en: "Sri Lanka",
    name_es: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name_en: "State of Palestine",
    name_es: "Estado de Palestina",
    dial_code: "+970",
    code: "PS",
  },
  {
    name_en: "Sudan",
    name_es: "Sudán",
    dial_code: "+249",
    code: "SD",
  },
  {
    name_en: "Suriname",
    name_es: "Surinam",
    dial_code: "+597",
    code: "SR",
  },
  {
    name_en: "Svalbard and Jan Mayen",
    name_es: "Svalbard y Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name_en: "Swaziland",
    name_es: "Suazilandia",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name_en: "Sweden",
    name_es: "Suecia",
    dial_code: "+46",
    code: "SE",
  },
  {
    name_en: "Switzerland",
    name_es: "Suiza",
    dial_code: "+41",
    code: "CH",
  },
  {
    name_en: "Syrian Arab Republic",
    name_es: "Siria",
    dial_code: "+963",
    code: "SY",
  },
  {
    name_en: "Taiwan, Province of China",
    name_es: "Taiwán",
    dial_code: "+886",
    code: "TW",
  },
  {
    name_en: "Tayikistan",
    name_es: "Tayikistán",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name_en: "Tanzania, United Republic of",
    name_es: "Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name_en: "Thailand",
    name_es: "Tailandia",
    dial_code: "+66",
    code: "TH",
  },
  {
    name_en: "Timor-Leste",
    name_es: "Timor Oriental",
    dial_code: "+670",
    code: "TL",
  },
  {
    name_en: "Togo",
    name_es: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name_en: "Tokelau",
    name_es: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name_en: "Tonga",
    name_es: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name_en: "Trinidad and Tobago",
    name_es: "Trinidad y Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name_en: "Tunisia",
    name_es: "Túnez",
    dial_code: "+216",
    code: "TN",
  },
  {
    name_en: "Turkey",
    name_es: "Turquía",
    dial_code: "+90",
    code: "TR",
  },
  {
    name_en: "Turkmenistan",
    name_es: "Turkmenistán",
    dial_code: "+993",
    code: "TM",
  },
  {
    name_en: "Turks and Caicos Islands",
    name_es: "Islas Turcas y Caicos",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name_en: "Tuvalu",
    name_es: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name_en: "Uganda",
    name_es: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name_en: "Ukraine",
    name_es: "Ucrania",
    dial_code: "+380",
    code: "UA",
  },
  {
    name_en: "United Arab Emirates",
    name_es: "Emiratos Árabes Unidos",
    dial_code: "+971",
    code: "AE",
  },
  {
    name_en: "United Kingdom",
    name_es: "Reino Unido",
    dial_code: "+44",
    code: "GB",
  },
  {
    name_en: "United States",
    name_es: "Estados Unidos",
    dial_code: "+1",
    code: "US",
  },
  {
    name_en: "Uruguay",
    name_es: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name_en: "Uzbekistan",
    name_es: "Uzbekistán",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name_en: "Vanuatu",
    name_es: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name_en: "Venezuela, Bolivarian Republic of",
    name_es: "Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name_en: "Vietnam",
    name_es: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name_en: "Virgin Islands, British",
    name_es: "Islas Vírgenes Británicas",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name_en: "Virgin Islands, U.S.",
    name_es: "Islas Vírgenes de los Estados Unidos",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name_en: "Wallis and Futuna",
    name_es: "Wallis y Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name_en: "Yemen",
    name_es: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name_en: "Zambia",
    name_es: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name_en: "Zimbabwe",
    name_es: "Zimbabue",
    dial_code: "+263",
    code: "ZW",
  },
  {
    name_en: "Åland Islands",
    name_es: "Åland",
    dial_code: "+358",
    code: "AX",
  },
];

export const ghostAdminId = 64;

export const contrastingHexColors = [
  "#6B39BD",
  "#82ca9d",
  "#FAC24B",
  "#E6194B",
  "#F58231",
  "#FFE119",
  "#BFEF45",
  "#3CB44B",
  "#D2F5EA",
  "#F5F5F5",
  "#FFD700",
  "#6B39BD",
  "#FFA500",
];

export function contarCaracteresV1(str) {
  const letra = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "ñ",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "á",
    "é",
    "í",
    "ó",
    "ú",
    "ü",
  ];
  let arreglo = [];
  str = str.split("");
  let lastWord = "";
  str.forEach(n => {
    if (letra.includes(n.toLowerCase())) {
      if (lastWord.trim() !== n.trim()) arreglo.push(n);
    }
    lastWord = n;
  });

  return arreglo.length;
}

export const ActionIcon = ({ type = feedsEnum.ONLINE_CHAT, className }) => {
  let Icon;
  switch (type) {
    case feedsEnum.ONLINE_CHAT:
      Icon = OnlineChatSvg;
      break;
    case feedsEnum.CHAT_MESSAGE:
    case feedsEnum.SUPERBOT_LINE:
      Icon = ChatSvg;
      break;
    case feedsEnum.PROFILE_VIDEO:
      Icon = ProfileVideoSvg;
      break;
    case feedsEnum.PRESENT:
      Icon = PresentSvg;
      break;
    case feedsEnum.MANUAL_BONUS:
      Icon = MedalSvg;
      break;
    case feedsEnum.SENT_EMAIL:
      Icon = EmailArrowRightGreenSvg;
      break;
    case feedsEnum.RECEIVED_EMAIL:
      Icon = EmailArrowLeftRedSvg;
      break;
    case feedsEnum.VIEWED_ATTACHMENT:
      Icon = ViewedAttachmentSvg;
      break;
    case feedsEnum.VIDEO_STREAM:
      Icon = WebcamSvg;
      break;
    case feedsEnum.GIFT:
      Icon = GiftBagSvg;
      break;
    case feedsEnum.SUPERBOT_AUDIO:
      Icon = MicrophoneSvg;
      break;
    case feedsEnum.VOICE_MESSAGE:
      Icon = MicrophoneSvg;
      break;
    case feedsEnum.MISSED_INVITE:
      Icon = MissedInviteSvg;
      break;
    case feedsEnum.MANUAL_WRITEOFF:
      Icon = ManualWriteoffSvg;
      break;
    case feedsEnum.WRITEOFF_FINE:
      Icon = WarningSvg;
      break;
    case feedsEnum.MISSED_ANSWER:
      Icon = MissedAnswerSvg;
      break;
    case feedsEnum.MISSED_MESSAGE:
      Icon = MissedMessageSvg;
      break;
    case feedsEnum.MISSED_EMAIL:
      Icon = MissedEmailSvg;
      break;
    case feedsEnum.UNANSWERED_EMAIL:
      Icon = UnansweredEmailSvg;
      break;
    case feedsEnum.MISSED_VIDEO_INVITE:
      Icon = MissedVideoInviteSvg;
      break;
    case feedsEnum.MISSED_PRESENT:
      Icon = MissedPresentSvg;
      break;
    case feedsEnum.OFFLINE_FINE:
      Icon = NoWifiSvg;
      break;
    case feedsEnum.MISS_FINE:
      Icon = Multa;
      break;
    case feedsEnum.MINGLE_FINE:
      Icon = Chat2Svg;
      break;
    case feedsEnum.LETTER_FINE:
      Icon = MultaCartaSvg;
      break;
    case feedsEnum.REPORT_FINE:
      Icon = ManMultaSvg;
      break;
    case feedsEnum.SUPERBOT_LETTER:
      Icon = CartaSvg;
      break;
    case feedsEnum.SUPERBOT_PHOTO:
      Icon = Foto2Svg;
      break;
    case feedsEnum.SUPERBOT_VIDEO:
      Icon = VideoStreamSvg;
      break;
    case feedsEnum.LISTENED_MESSAGE:
      Icon = MicrophoneSendSvg;
      break;
    case feedsEnum.RECEIVED_MESSAGE:
      Icon = MicrophoneReceiveSvg;
      break;
    case feedsEnum.MANUAL_FINE:
      Icon = ManualWriteoffSvg;
      break;
    default:
      Icon = QuestionSvg;
      break;
  }

  return <Icon className={className} />;
};

export function DateConvert(date = new Date()) {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }`;
}

export const useFilterGraphFilter = () => {
  const [showClientesNew, setShowClientesNew] = useState(false);
  const [showClientesOld, setShowClientesOld] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showClients, setShowClients] = useState(false);

  const [showOnlineChat, setShowOnlineChat] = useState(true);
  const [showChatMessage, setShowChatMessage] = useState(true);
  const [showReceivedEmail, setShowReceivedEmail] = useState(true);
  const [showSentEmail, setShowSentEmail] = useState(true);
  const [showViewedAttachment, setShowViewedAttachment] = useState(true);
  const [showVideoStream, setShowVideoStream] = useState(true);
  const [showProfileVideo, setShowProfileVideo] = useState(true);
  const [showPresent, setShowPresent] = useState(true);
  const [showManualBonus, setShowManualBonus] = useState(true);
  const [showGift, setShowGift] = useState(true);
  const [showVoiceMessage, setShowVoiceMessage] = useState(true);
  const [showAudioEscuchado, setShowAudioEscuchado] = useState(true);
  const [showAudioRecibido, setShowAudioRecibido] = useState(true);

  const [showInvitacionesRecibidas, setShowInvitacionesRecibidas] = useState(true);
  const [showInvitacionesPerdidas, setShowInvitacionesPerdidas] = useState(true);
  const [showMinglesEnviados, setShowMinglesEnviados] = useState(true);
  const [showInvitacionesManuales, setShowInvitacionesManuales] = useState(true);
  const [showCantidadDeChats, setShowCantidadDeChats] = useState(true);
  const [showPromedioDeChats, setShowPromedioDeChats] = useState(true);
  const [showDiasActivos, setShowDiasActivos] = useState(true);
  const [showOnline, setShowOnline] = useState(true);
  const [showOnlineConCamara, setShowOnlineConCamara] = useState(true);

  const [showMissedInvite, setShowMissedInvite] = useState(true);
  const [showMissedAnswer, setShowMissedAnswer] = useState(true);
  const [showMissedMessage, setShowMissedMessage] = useState(true);
  const [showUnansweredEmail, setShowUnansweredEmail] = useState(true);
  const [showMissedEmail, setShowMissedEmail] = useState(true);
  const [showMissedVideoInvite, setShowMissedVideoInvite] = useState(true);
  const [showMissedPresent, setShowMissedPresent] = useState(true);
  const [showManualWriteoff, setShowManualWriteoff] = useState(true);
  const [showPoints, setShowPoints] = useState(false);

  return {
    showClients,
    setShowClients,
    showReports,
    setShowReports,
    showClientesOld,
    setShowClientesOld,
    showClientesNew,
    setShowClientesNew,
    showOnlineChat,
    setShowOnlineChat,
    showChatMessage,
    setShowChatMessage,
    showReceivedEmail,
    setShowReceivedEmail,
    showSentEmail,
    setShowSentEmail,
    showViewedAttachment,
    setShowViewedAttachment,
    showVideoStream,
    setShowVideoStream,
    showProfileVideo,
    setShowProfileVideo,
    showPresent,
    setShowPresent,
    showManualBonus,
    setShowManualBonus,
    showGift,
    setShowGift,
    showVoiceMessage,
    setShowVoiceMessage,
    showMissedInvite,
    setShowMissedInvite,
    showMissedAnswer,
    setShowMissedAnswer,
    showMissedMessage,
    setShowMissedMessage,
    showUnansweredEmail,
    setShowUnansweredEmail,
    showMissedEmail,
    setShowMissedEmail,
    showMissedVideoInvite,
    setShowMissedVideoInvite,
    showMissedPresent,
    setShowMissedPresent,
    showManualWriteoff,
    setShowManualWriteoff,
    showAudioEscuchado,
    setShowAudioEscuchado,
    showAudioRecibido,
    setShowAudioRecibido,
    showPromedioDeChats,
    setShowPromedioDeChats,
    showCantidadDeChats,
    setShowCantidadDeChats,
    showInvitacionesManuales,
    setShowInvitacionesManuales,
    showMinglesEnviados,
    setShowMinglesEnviados,
    showInvitacionesPerdidas,
    setShowInvitacionesPerdidas,
    showInvitacionesRecibidas,
    setShowInvitacionesRecibidas,
    showDiasActivos,
    setShowDiasActivos,
    showOnline,
    setShowOnline,
    showOnlineConCamara,
    setShowOnlineConCamara,
    showPoints,
    setShowPoints,
  };
};
