import { queryCache } from "react-query";
import jsCookie from "js-cookie";
import {
  alertToast,
  bonusTypes,
  mandarNotificacion,
  refetchOperador,
  refetchPerfil,
  writeoffTypes,
} from "components/Utilidades";

window.queryCache = queryCache;

let ws;
const wsEndpoint = "https://ws.admin.scalebot.net";
  // process.env.NODE_ENV === "production" ? "wss://api." + window.location.host + "/_ws_/" : "ws://localhost:8765";

const getCachedPerfilFromList = id => {
  return queryCache.getQueryData(["perfiles"])?.find(perfil => perfil.id === id);
};

const updateCachedPerfilFromList = (id, data) => {
  queryCache.setQueryData(["perfiles"], perfiles => {
    const perfil = perfiles?.find((p, i) => p.id === id);
    if (perfil) {
      let i = -1;
      for (let p of perfiles) {
        i++;
        if (p.id === id) {
          perfiles[i] = { ...p, ...data };
          break;
        }
      }
    }

    return perfiles;
  });
};

const getCachedPerfilFromOperador = perfilId => {
  const operadores = queryCache.getQueryData(["operadores"]) ?? [];

  let perfil;

  for (const operador of operadores) {
    let encontrado = false;

    for (const p of operador.perfiles) {
      if (p.id === perfilId) {
        perfil = p;
        encontrado = true;
        break;
      }
    }

    if (encontrado) break;
  }

  return perfil;
};

const updateCachedPerfilFromOperador = (perfilId, data) => {
  queryCache.setQueryData(["operadores"], operadores => {
    if (!operadores) return operadores;

    let i = -1;
    for (let operador of operadores) {
      i++;
      let j = -1;
      let terminar = false;
      for (let p of operador.perfiles) {
        j++;
        if (p.id === perfilId) {
          operadores[i].perfiles[j] = { ...p, ...data };
          terminar = true;
          break;
        }
      }

      if (terminar) break;
    }

    return operadores;
  });
};

setInterval(() => {
  if (ws?.readyState !== WebSocket.OPEN && ws?.readyState !== WebSocket.CONNECTING) {
    ws = new WebSocket(wsEndpoint);

    ws.addEventListener("message", async e => {
      const agency_id = +jsCookie.get("agency_id");
      const id = +jsCookie.get("id");
      const msg = JSON.parse(e.data);
      if (msg.type === "activity") {
        const queryKey = queryCache.getQueries(["feeds"]).find(query => query.observers.length === 1).queryKey;
        const operadores = queryCache.getQueryData(["operadores"]);
        const supervisorDeActivity = operadores?.find(
          o => o.id === +(msg.data.extraUserId ?? msg.data.userId),
        )?.supervisorId;
        const suplenteDeActivity = operadores?.find(
          s => s.substitute === (msg.data.extraUserId ?? msg.data.userId),
        )?.id;
        if (queryKey[1] === 1) {
          if (
            queryKey[2] === "todos" ||
            (queryKey[2] === "bonus" && bonusTypes.find(bt => bt.value === msg.data.data0)) ||
            (queryKey[2] === "writeoff" && writeoffTypes.find(wt => wt.value === msg.data.data0)) ||
            (queryKey[2] === "multas" && msg.data.tablename === "fines") ||
            (queryKey[2] === "alertas" && msg.data.tablename === "fine_alerts") ||
            (queryKey[2] === "cartas24" && msg.data.tablename === "bot_letters_sent") ||
            (queryKey[2] === "superbot-letters" && msg.data.tablename === "super_bot_letter_deliveries")
          ) {
            if (queryKey[3] === "todos" || queryKey[3] === msg.data.data0) {
              if (queryKey[4] === "todos" || +queryKey[4] === +msg.data.perfilId) {
                if (
                  (queryKey[5].entity === "all" && (agency_id === msg.data.agencyId || agency_id === 1)) ||
                  (queryKey[5].entity === "user" && +queryKey[5].id === +(msg.data.extraUserId ?? msg.data.userId)) ||
                  (queryKey[5].entity === "supervisor" &&
                    (+queryKey[5].ids.includes(supervisorDeActivity) ||
                      +queryKey[5].ids.includes(suplenteDeActivity) ||
                      +queryKey[5].ids.includes(msg.data.extraUserId ?? msg.data.userId))) ||
                  (queryKey[5].entity === "shift" &&
                    (+queryKey[5].ids.includes(supervisorDeActivity) ||
                      +queryKey[5].ids.includes(suplenteDeActivity) ||
                      +queryKey[5].ids.includes(msg.data.extraUserId ?? msg.data.userId))) ||
                  (queryKey[5].entity === "agency" && +queryKey[5].id === msg.data.agencyId)
                ) {
                  switch (msg.data.tablename) {
                    case "feeds":
                      mandarNotificacion("Feed", "bonus");
                      break;
                    case "fine":
                      mandarNotificacion("Multa", "writeoff");
                      break;
                    case "fine_alerts":
                      mandarNotificacion("Aviso de multa", "fineAlert");
                      break;
                    default:
                      break;
                  }
                  queryCache.setQueryData(queryKey, oldActivity => {
                    if (!oldActivity) return oldActivity;

                    let activityData = [msg.data, ...oldActivity.data];

                    activityData.sort((a, b) => {
                      return b.timestamp > a.timestamp ? 1 : -1;
                    });

                    activityData = activityData.slice(0, 20);

                    return { _meta: oldActivity._meta, data: activityData };
                  });
                }
              }
            }
          }
        }
      } else if (msg.type === "mingle" || msg.type === "presence") {
        const perfil = getCachedPerfilFromList(msg.profileId);
        const perfilDeOperador = getCachedPerfilFromOperador(msg.profileId);

        if (msg.type === "mingle" && msg.action === "add") {
          if (perfil) {
            perfil.mingle = true;
            perfil.missingMingleTimestamp = null;
          }

          if (perfilDeOperador) {
            perfilDeOperador.mingle = true;
            perfilDeOperador.missingMingleTimestamp = null;
          }
        } else if (msg.type === "mingle" && msg.action === "remove") {
          if (perfil) {
            perfil.mingle = false;
            perfil.missingMingleTimestamp = msg.timestamp;
          }

          if (perfilDeOperador) {
            perfilDeOperador.mingle = false;
            perfilDeOperador.missingMingleTimestamp = msg.timestamp;
          }
        } else if (msg.type === "presence" && msg.action === "online") {
          if (perfil) {
            perfil.online = true;
            perfil.offlineTimestamp = null;
          }

          if (perfilDeOperador) {
            perfilDeOperador.online = true;
            perfilDeOperador.offlineTimestamp = null;
          }
        } else if (msg.type === "presence" && msg.action === "offline") {
          if (perfil) {
            perfil.online = false;
            perfil.offlineTimestamp = msg.timestamp;
          }

          if (perfilDeOperador) {
            perfilDeOperador.online = false;
            perfilDeOperador.offlineTimestamp = msg.timestamp;
          }
        }

        if (perfil) updateCachedPerfilFromList(msg.profileId, perfil);
        if (perfilDeOperador) updateCachedPerfilFromOperador(msg.profileId, perfilDeOperador);
      } else if (msg.type === "letter") {
        const perfil = getCachedPerfilFromList(msg.profileId);
        const perfilDeOperador = getCachedPerfilFromOperador(msg.profileId);

        if (msg.action === "add") {
          if (perfil) {
            perfil.unrepliedLettersTimestamps.push(msg.timestamp);
          }

          if (perfilDeOperador) {
            perfilDeOperador.unrepliedLettersTimestamps.push(msg.timestamp);
          }
        } else if (msg.action === "remove") {
          if (perfil) {
            perfil.unrepliedLettersTimestamps = perfil.unrepliedLettersTimestamps.filter(t => t !== msg.timestamp);
          }

          if (perfilDeOperador) {
            perfilDeOperador.unrepliedLettersTimestamps = perfilDeOperador.unrepliedLettersTimestamps.filter(
              t => t !== msg.timestamp,
            );
          }
        }

        if (perfil) updateCachedPerfilFromList(msg.profileId, perfil);
        if (perfilDeOperador) updateCachedPerfilFromOperador(msg.profileId, perfilDeOperador);
      } else if (msg.type === "profile-ownership" && (+msg.agencyId === agency_id || agency_id === 1)) {
        await refetchPerfil(msg.profileId);
        await refetchOperador(msg.userId);
      } else if (msg.type === "refresh-profile" && (+msg.agencyId === agency_id || agency_id === 1)) {
        await refetchPerfil(msg.profileId);
        await refetchOperador(msg.userId);
      } else if (msg.type === "refresh-profile" && (+msg.agencyId === agency_id || agency_id === 1)) {
        await refetchPerfil(msg.profileId);
      } else if (msg.type === "operator-pause-unpause" && (+msg.agencyId === agency_id || agency_id === 1)) {
        const perfilesIds = [];
        const operadoresExtraIds = [];
        const perfiles = queryCache.getQueryData(["perfiles"]) ?? [];

        for (const perfil of perfiles) {
          if (perfil.operador?.id === msg.userId || perfil.operadorDePausa?.id === msg.userId) {
            perfilesIds.push(perfil.id);

            if (msg.action === "unpause" && perfil.operador?.id) {
              operadoresExtraIds.push(perfil.operador?.id);
            }
          }
        }

        await refetchOperador(msg.userId);

        for (const perfilId of perfilesIds) {
          await refetchPerfil(perfilId);
        }

        for (const operadorExtraId of operadoresExtraIds) {
          await refetchOperador(operadorExtraId);
        }
      } else if (msg.type === "super-bot-state") {
        queryCache.setQueryData(["super-bot-state"], superbotState => {
          const { sendableType, sendableId, remaining, deliveryId, state, timestamp } = msg;

          if (state === "") {
            if (superbotState) {
              delete superbotState[msg.profileId];
            }

            return superbotState;
          }

          if (!superbotState) {
            return { [msg.profileId]: { type: sendableType, sendableId, remaining, deliveryId, state, timestamp } };
          }

          superbotState[msg.profileId] = { type: sendableType, sendableId, remaining, deliveryId, state, timestamp };
          return superbotState;
        });
      } else if (msg.type === "alert") {
        const id = +jsCookie.get("id");
        if (id === +msg.userId) {
          alertToast("report", msg.response);
          await queryCache.invalidateQueries(["reportOp"]);
          return true;
        }
      } else if (msg.type === "change-shift") {
        const id = +jsCookie.get("id");
        if (id === +msg.userId) {
          jsCookie.set("shift", +msg.shift);
          return true;
        }
      } else if (msg.type === "letters-update" && (+msg.agencyId === agency_id || agency_id === 1)) {
        queryCache.invalidateQueries(["unreplied-letters", agency_id, id]);
      }
    });

    ws.addEventListener("open", () => {
      const role = jsCookie.get("role");
      const id = jsCookie.get("id");

      if (role && id) {
        if (role === "admin") {
          subscribeToAdmin();
        } else if (role === "operator" || role === "supervisor" || role === "support" || role === "shift") {
          subscribeToUser(id);
        }
      }
    });
  }
}, 10000);

const subscribeToUser = id => {
  const agency_id = +jsCookie.get("agency_id");
  let entities =
    agency_id === 1 ? { userId: +id, profileId: "all" } : { userId: +id, agencyId: agency_id, profileId: "all" };

  if (ws?.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({
        type: "subscribe",
        subInfo: { entities: entities, activities: "all" },
      }),
    );
  } else
    setTimeout(() => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(
          JSON.stringify({
            type: "subscribe",
            subInfo: { entities: entities, activities: "all" },
          }),
        );
      }
    }, 5000);
};

const subscribeToAdmin = () => {
  const agency_id = +jsCookie.get("agency_id");
  let entities = agency_id === 1 ? "all" : { agencyId: agency_id, profileId: "all" };
  if (ws?.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify({ type: "subscribe", subInfo: { entities: entities, activities: "all" } }));
  } else
    setTimeout(() => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(JSON.stringify({ type: "subscribe", subInfo: { entities: entities, activities: "all" } }));
      }
    }, 5000);
};

export const unsubscribe = () => {
  if (ws?.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify({ type: "unsubscribe" }));
  }
};

export const subscribeToSupervisor = id => {
  const agency_id = +jsCookie.get("agency_id");
  let entities =
    agency_id === 1
      ? { supervisorId: +id, profileId: "all" }
      : { supervisorId: +id, agencyId: agency_id, profileId: "all" };
  if (ws?.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({
        type: "subscribe",
        subInfo: { entities: entities, activities: "all" },
      }),
    );
  } else
    setTimeout(() => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(
          JSON.stringify({
            type: "subscribe",
            subInfo: { entities: entities, activities: "all" },
          }),
        );
      }
    }, 5000);
};

export const subscribeToAgency = id => {
  const agency_id = +jsCookie.get("agency_id");
  let entities = agency_id === 1 ? { agencyId: +id, profileId: "all" } : { agencyId: agency_id, profileId: "all" };
  if (ws?.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({
        type: "subscribe",
        subInfo: { entities: entities, activities: "all" },
      }),
    );
  } else
    setTimeout(() => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(
          JSON.stringify({
            type: "subscribe",
            subInfo: { entities: agency_id, activities: "all" },
          }),
        );
      }
    }, 5000);
};

export { ws, subscribeToUser, subscribeToAdmin };
