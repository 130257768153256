import { css } from "styled-components/macro";
import tw from "twin.macro";

export const componenteRadial = css`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  position: absolute;
  left: ${({ angulo, width, r1, r2 }) => r2 * Math.cos((angulo * Math.PI) / 180) + r1 - width / 2}px;
  top: ${({ angulo, width, r1, r2 }) => -r2 * Math.sin((angulo * Math.PI) / 180) + r1 - width / 2}px;
  transform-origin: 50% 50%;
  transform: rotate(${({ angulo }) => -angulo + 90}deg);
`;

export const scrollbarStyle = css`
  overflow-y: auto;
  &::-webkit-scrollbar {
    @media (min-width: 2560px) {
      width: 12px !important;
      height: 12px !important;
    }
    ${tw`
      w-[6px]
      h-[6px]
    `}
  }

  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 1px solid #17171c;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #17171c;
  }
`;
