import styled, { css } from "styled-components/macro";
import tw from "twin.macro";

const Button = styled.button`
  @media (min-width: 2560px) {
    width: 200px !important;
    height: 45px !important;
  }
  ${tw`
    border-radius[5px]
    cursor-pointer
    2xl:w-[170px]
    2xl:h-[40px]
    xl:w-[140px]
    xl:h-[35px]
    lg:w-[110px]
    lg:h-[30px]
    md:w-[80px]
    md:h-[25px]
  `}
  border: none;
  background: none;
  color: white;
  letter-spacing: 0.1em;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.2s ease-in-out;

  ${({ primario }) =>
    primario
      ? css`
          border: 2px solid #3346ff;
          background-color: #3346ff;

          &:hover {
            background-color: rgba(51, 70, 255, 0);
          }
        `
      : ""}

  ${({ info }) =>
    info
      ? css`
          border: 2px solid #05574d;
          background-color: #05574d;

          &:hover {
            background-color: rgba(51, 70, 255, 0);
          }
        `
      : ""}

  ${({ green }) =>
    green
      ? css`
          border: 2px solid #04c292;
          background-color: #04c292;

          &:hover {
            background-color: rgba(51, 70, 255, 0);
          }
        `
      : ""}

  ${({ yellow }) =>
    yellow
      ? css`
          border: 2px solid #facc15;
          background-color: #facc15;
          color: #000000;

          &:hover {
            background-color: rgba(51, 70, 255, 0);
            color: #ffffff;
          }
        `
      : ""}

  ${({ cancel }) =>
    cancel
      ? css`
          border: 2px solid #ff3333;
          background-color: rgba(255, 51, 51, 0);
          &:hover {
            background-color: #ff3333;
          }
        `
      : ""}
`;

export const BtnWSvg = styled.button`
  @media (min-width: 2560px) {
    width: 42px !important;
    height: 42px !important;
  }
  ${tw`
    2xl:w-[32px]
    2xl:h-[32px]
    xl:w-[24px]
    xl:h-[24px]
    lg:w-[16px]
    lg:h-[16px]
    w-[16px]
    h-[16px]
  `}
  position: relative;
  cursor: pointer;
  color: #4c4d3e;

  svg {
    width: 100%;
    height: 100%;

    * {
      fill: #4c4c3f;
      transition: fill 0.2s ease-in-out;
    }
  }

  ${({ red }) =>
    red
      ? css`
          &:hover {
            cursor: pointer;
            color: #e02424;
          }

          &:hover > svg * {
            fill: #e02424;
          }
        `
      : ""}

  ${({ blue }) =>
    blue
      ? css`
          &:hover {
            cursor: pointer;
            color: #3346ff;
          }

          &:hover > svg * {
            fill: #3346ff;
          }
        `
      : ""}
  ${({ green }) =>
    green
      ? css`
          &:hover {
            cursor: pointer;
            color: #04c292;
          }

          &:hover > svg * {
            fill: #04c292;
          }
        `
      : ""}
`;
export default Button;
