import styled from "styled-components/macro";
import tw from "twin.macro";

const Container = styled.div`
  @media (min-width: 2560px) {
    padding: 32px !important;
  }
  @media (min-height: 1000px) and (max-width: 767px) {
    padding: 32px !important;
  }
  ${tw`
    bg-[#1f1f26]
    overflow-hidden
    relative
    max-h-[100%]
    md:border-radius[8px]
    lg:p-[12px]
    md:p-[8px]
    p-[8px]
    border-radius[4px]
    md:letter-spacing[0.08em]
  `}
  display: inline-flex;
  flex-flow: column;
  min-height: 0;
`;

export default Container;
