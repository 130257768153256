import styled, { css } from "styled-components/macro";
import tw from "twin.macro";

export const ContainerTagDiv = styled.div`
  @media (min-width: 2560px) {
    font-size: 0.75rem !important;
  }
  @media (min-height: 1000px) and (max-width: 767px) {
    font-size: 0.75rem !important;
  }
  ${tw`
    flex
    relative
    text-[#4c4c3f]
    2xl:font-size[0.6rem]
    xl:font-size[0.5rem]
    lg:font-size[0.38rem]
    md:font-size[0.28rem]
    font-size[0.28rem]
  `}
  span {
    font-weight: 600;
  }
`;

const ContainerTagLine = styled.div`
  height: 1px;
  margin-top: 7px;
  background-color: #4c4c3f;
  flex: 1 1 auto;

  ${({ side }) =>
    side === "right"
      ? css`
          margin-left: 16px;
        `
      : css`
          margin-right: 16px;
        `}
`;

const ContainerTag = ({ txt, position, className }) => {
  return (
    <ContainerTagDiv className={className}>
      {position === "center" && <ContainerTagLine side="left" />}
      <span>{txt}</span>
      <ContainerTagLine side="right" />
    </ContainerTagDiv>
  );
};

export default ContainerTag;
