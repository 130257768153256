import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { ReactComponent as OfflineSvg } from "assets/offline.svg";
import { useTriggerOfflineMessage } from "reduxStuff";
import tw from "twin.macro";

const BadgeBg = styled.div`
  @media (min-width: 2560px) {
    padding: 16px 24px !important;
  }
  ${tw`
  flex
  items-center
  fixed
  z-index[10]
  bg-[#ff3333]
  border-radius[0 0 2px 2px]
  2xl:padding[10px 18px]
  xl:padding[8px 14px]
  lg:padding[8px 14px]
  md:padding[4px 8px]
  padding[4px 8px]
  overflow-hidden
  `}
  top: 0;
  left: 32px;
  transition: max-width 1s ease-in-out;
  ${({ showOfflineMessage }) =>
    showOfflineMessage
      ? tw`
    max-w-[2000px]
  `
      : css`
          @media (min-width: 2560px) {
            max-width: 80px !important;
          }
          ${tw`
            2xl:max-w-[61px]
            xl:max-w-[50px]
            lg:max-w-[42px]
            md:max-w-[28px]
            max-w-[28px]
          `}
        `}

  & svg {
    @media (min-width: 2560px) {
      width: 32px !important;
      height: 32px !important;
    }
    ${tw`
      2xl:h-[24px]
      2xl:w-[24px]
      xl:h-[20px]
      xl:w-[20px]
      lg:h-[16px]
      lg:w-[16px]
      md:w-[12px]
      md:h-[12px]
      w-[12px]
      h-[12px]
    `}
    flex-shrink: 0;
  }

  & svg * {
    fill: white;
  }
`;

const OfflineMessage = styled.span`
  @media (min-width: 2560px) {
    font-size: 1.25rem !important;
    margin-left: 24px !important;
  }
  ${tw`
  2xl:font-size[0.75rem]
  2xl:ml-[20px]
  xl:font-size[0.6rem]
  xl:ml-[16px]
  lg:font-size[0.5rem]
  lg:ml-[12px]
  md:font-size[0.3rem]
  md:ml-[8px]
  font-size[0.3rem]
  ml-[8px]
  text-white
  whitespace-nowrap
  `}
`;

const OfflineBadge = () => {
  const showOfflineMessage = useSelector(state => state.showOfflineMessage);
  const triggerOfflineMessage = useTriggerOfflineMessage();

  useEffect(() => {
    triggerOfflineMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BadgeBg onMouseEnter={triggerOfflineMessage} showOfflineMessage={showOfflineMessage}>
      <OfflineSvg />
      <OfflineMessage>Sin conexion - No podra hacer o ver cambios hasta arreglar el problema</OfflineMessage>
    </BadgeBg>
  );
};

export default OfflineBadge;
