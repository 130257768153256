import styled, { css } from "styled-components/macro";

const Input = styled.input`
  background-color: #17171c;
  border: none;
  border-radius: 2px;
  padding: 11px 16px;
  color: white;
  letter-spacing: 0.1em;
  font-size: 12px;

  &::placeholder {
    font-family: "Montserrat", sans-serif;
    color: lightgray;
    letter-spacing: 0.1em;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  grid-area: ${({ placeholder }) => {
    if (placeholder === "Año") return "year";
    return placeholder?.toLowerCase() ?? "";
  }};

  ${({ id }) => {
    if (id === "foto") {
      return css`
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -100;
      `;
    }
  }}
`;

export default Input;
