import { createStore } from "redux";
import { useDispatch, useSelector } from "react-redux";
import jsCookie from "js-cookie";
import { useHistory } from "react-router-dom";
import { apiOrigin } from "components/Utilidades";
import { useCallback } from "react";
import { queryCache } from "react-query";

const defaultState = {
  enLinea: true,
  showOfflineMessage: false,
  offlineMessageIntervalId: undefined,
  sessionToken: jsCookie.get("sessionToken") ?? "",
  role: jsCookie.get("role") ?? "",
  id: Number(jsCookie.get("id")) ?? "",
  registrarOperadorVisible: false,
  actualizarTareasVisible: false,
  agenciaNombre: "Nombre De Agencia",
  multaReal: 0,
  agenciaFoto: undefined,
  subAgencias: [{ progreso: 0, progresoMaximo: 0 }],
  turno1Progreso: 1000,
  turno1ProgresoMaximo: 1000,
  turno2Progreso: 500,
  turno2ProgresoMaximo: 1000,
  operadores: [],
  perfiles: [],
  clientes: [],
  mostrarTomados: false,
  cartas: [],
  diasLibres: [],
  cumples: [],
  faltas: [],
  notificacionesCarta: [],
  cartasAnteriores: [],
  cantidadPaginas: 0,
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case "REGISTRAR_OPERADOR_SHOW":
      return { ...state, registrarOperadorVisible: true };
    case "REGISTRAR_OPERADOR_HIDE":
      return { ...state, registrarOperadorVisible: false };
    case "ACTUALIZAR_TAREAS_SHOW":
      return { ...state, actualizarTareasVisible: true };
    case "ACTUALIZAR_TAREAS_HIDE":
      return { ...state, actualizarTareasVisible: false };
    case "SET_TOKEN":
      return { ...state, sessionToken: action.load };
    case "SET_OPERADORES_DATA":
      return { ...state, operadores: action.load };
    case "SET_ENLINEA":
      return { ...state, enLinea: action.load };
    case "SET_SHOW_OFFLINE_MESSAGE":
      return { ...state, showOfflineMessage: action.load.show, offlineMessageIntervalId: action.load.id };
    case "SET_MOSTRAR_TOMADOS":
      return { ...state, mostrarTomados: action.load };
    case "LOGIN":
      return {
        ...state,
        sessionToken: action.load.sessionToken,
        role: action.load.role,
        id: action.load.id,
        agency_id: action.load.agency_id,
      };
    case "LOGOUT":
      return { ...state, sessionToken: "", role: "", id: "", agency_id: -1 };
    case "DIAS_LIBRES":
      return { ...state, diasLibres: action.load };
    case "FALTAS":
      return { ...state, faltas: action.load };
    case "SET_CARTAS":
      return { ...state, cartas: action.load, cartasAnteriores: action.load };
    case "SET_CARTAS_NUEVAS":
      let newCartas = action.load;
      let oldCartas = state.cartas;

      newCartas = newCartas.filter(carta => {
        if (carta.respondida) {
          oldCartas.splice(
            oldCartas.findIndex(c => c.cartaId === carta.cartaId),
            1,
          );

          return false;
        }

        return true;
      });

      return { ...state, cartas: [...oldCartas, ...newCartas] };
    case "SET_PERFILES_DATA":
      return { ...state, perfiles: action.load };
    case "SET_CLIENTES_DATA":
      return { ...state, clientes: action.load.clientes, cantidadPaginas: action.load.cantidadPaginas };
    case "SET_NOTIFICACIONES_CARTA":
      return { ...state, notificacionesCarta: action.load };
    case "CLEAR_NOTIFICACIONES_CARTA":
      return { ...state, notificacionesCarta: [] };
    case "SET_CARTAS_ANTERIORES":
      return { ...state, cartasAnteriores: action.load };
    default:
      return state;
  }
}

const parseClientes = clientesRaw => {
  const clientes = clientesRaw.map(({ id, amolatina_id, name, crowns, progress_total }) => {
    return {
      id,
      idAmolatina: amolatina_id,
      nombre: name,
      coronas: crowns,
      asociados: [],
      progreso: Math.floor(progress_total),
    };
  });

  return clientes;
};

export const useRegistrarOperadorShow = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch({ type: "REGISTRAR_OPERADOR_SHOW" });
  };
};

export const useRegistrarOperadorHide = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch({ type: "REGISTRAR_OPERADOR_HIDE" });
  };
};

export const useActualizarTareasShow = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch({ type: "ACTUALIZAR_TAREAS_SHOW" });
  };
};

export const useActualizarTareasHide = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch({ type: "ACTUALIZAR_TAREAS_HIDE" });
  };
};

export const useSetToken = () => {
  const dispatch = useDispatch();
  return token => {
    dispatch({ type: "SET_TOKEN", load: token });
  };
};

export const useSetOperadoresData = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    data => {
      dispatch({ type: "SET_OPERADORES_DATA", load: data });
    },
    [dispatch],
  );

  return callback;
};

export const useSetEnLinea = () => {
  const dispatch = useDispatch();
  return enLinea => {
    dispatch({ type: "SET_ENLINEA", load: enLinea });
  };
};

export const useFetchOperadoresInfo = () => {
  const setOperadoresData = useSetOperadoresData();
  const dispatch = useDispatch();

  const callback = useCallback(
    async sessionToken => {
      try {
        let response = await fetch(apiOrigin + "/operators/list", {
          headers: {
            "X-Api-key": sessionToken,
          },
        });

        let operadores = (await response.json()).data.map(
          ({
            id,
            username,
            name,
            surname,
            photo,
            isOnline,
            fine,
            stars,
            progress_month,
            progress_day,
            milestone_month,
            rank,
            free_days,
            missed_days,
            paused,
          }) => {
            // Ordenar dias libres y faltas en orden cronologico
            free_days.sort((a, b) => (a.year - b.year) * 1000000 + (a.month - b.month) * 1000 + (a.day - b.day));
            missed_days.sort((a, b) => (a.year - b.year) * 1000000 + (a.month - b.month) * 1000 + (a.day - b.day));

            return {
              id: id,
              nombre: name,
              apellido: surname,
              cedula: username,
              foto: photo,
              online: isOnline,
              multas: fine,
              estrellas: stars,
              progreso: Math.floor(progress_month),
              progresoDia: Math.floor(progress_day),
              progresoMaximo: milestone_month,
              diasLibresOperador: free_days.map(({ day, month, year }) => ({ dia: day, mes: month, year })),
              faltasOperador: missed_days.map(({ day, month, year }) => ({ dia: day, mes: month, year })),
              medallas: rank, //NOTA - Probablemente cambie Ricardo no ponga "medals" sino otra cosa
              pausado: paused,
            };
          },
        );
        setOperadoresData(operadores);

        // Mapea los dias libres de cada operador a un unico array de dias libres
        let newDiasLibres = operadores.reduce((diasLibresAccumulator, { id, diasLibresOperador }) => {
          diasLibresOperador.forEach(dlo => {
            const diaLibreMatch = diasLibresAccumulator.find(dla => {
              return dla.dia === dlo.dia && dla.mes === dlo.mes && dla.year === dlo.year;
            });

            if (!diaLibreMatch)
              diasLibresAccumulator.push({ dia: dlo.dia, mes: dlo.mes, year: dlo.year, operadores: [id] });
            else diaLibreMatch.operadores.push(id);
          });

          return diasLibresAccumulator;
        }, []);

        dispatch({ type: "DIAS_LIBRES", load: newDiasLibres });

        // Mapea las faltas de cada operador a un unico array de faltas
        let newFaltas = operadores.reduce((faltasAccumulator, { id, faltasOperador }) => {
          faltasOperador.forEach(fo => {
            const faltaMatch = faltasAccumulator.find(fa => {
              return fa.dia === fo.dia && fa.mes === fo.mes && fa.year === fo.year;
            });

            if (!faltaMatch) faltasAccumulator.push({ dia: fo.dia, mes: fo.mes, year: fo.year, operadores: [id] });
            else faltaMatch.operadores.push(id);
          });

          return faltasAccumulator;
        }, []);

        dispatch({ type: "FALTAS", load: newFaltas });
      } catch {}
    },
    [dispatch, setOperadoresData],
  );

  return callback;
};

export const useFetchOperadorInfo = () => {
  const setOperadoresData = useSetOperadoresData();
  const dispatch = useDispatch();

  const callback = useCallback(
    async (sessionToken, id) => {
      try {
        let response = await fetch(apiOrigin + `/operators/info?id=${id}`, {
          headers: {
            "X-Api-key": sessionToken,
          },
        });

        let operador = [(await response.json()).data].map(
          ({
            id,
            username,
            name,
            surname,
            photo,
            isOnline,
            fine,
            stars,
            progress_month,
            milestone_month,
            progress_day,
            rank,
            free_days,
            missed_days,
            paused,
          }) => {
            // Ordenar dias libres y faltas en orden cronologico
            free_days.sort((a, b) => (a.year - b.year) * 1000000 + (a.month - b.month) * 1000 + (a.day - b.day));
            missed_days.sort((a, b) => (a.year - b.year) * 1000000 + (a.month - b.month) * 1000 + (a.day - b.day));

            return {
              id: id,
              nombre: name,
              apellido: surname,
              cedula: username,
              foto: photo,
              online: isOnline,
              multas: fine,
              estrellas: stars,
              progreso: Math.floor(progress_month),
              progresoDia: Math.floor(progress_day),
              progresoMaximo: milestone_month,
              diasLibresOperador: free_days.map(({ day, month, year }) => ({ dia: day, mes: month, year })),
              faltasOperador: missed_days.map(({ day, month, year }) => ({ dia: day, mes: month, year })),
              medallas: rank, //NOTA - Probablemente cambie Ricardo no ponga "medals" sino otra cosa
              pausado: paused,
            };
          },
        );
        setOperadoresData(operador);

        // Mapea los dias libres del operador a un unico array de dias libres
        let newDiasLibres = operador.reduce((diasLibresAccumulator, { id, diasLibresOperador }) => {
          diasLibresOperador.forEach(dlo => {
            const diaLibreMatch = diasLibresAccumulator.find(dla => {
              return dla.dia === dlo.dia && dla.mes === dlo.mes && dla.year === dlo.year;
            });

            if (!diaLibreMatch)
              diasLibresAccumulator.push({ dia: dlo.dia, mes: dlo.mes, year: dlo.year, operadores: [id] });
            else diaLibreMatch.operadores.push(id);
          });

          return diasLibresAccumulator;
        }, []);

        dispatch({ type: "DIAS_LIBRES", load: newDiasLibres });

        // Mapea las falta del operador a un unico array de faltas
        let newFaltas = operador.reduce((faltasAccumulator, { id, faltasOperador }) => {
          faltasOperador.forEach(fo => {
            const faltaMatch = faltasAccumulator.find(fa => {
              return fa.dia === fo.dia && fa.mes === fo.mes && fa.year === fo.year;
            });

            if (!faltaMatch) faltasAccumulator.push({ dia: fo.dia, mes: fo.mes, year: fo.year, operadores: [id] });
            else faltaMatch.operadores.push(id);
          });

          return faltasAccumulator;
        }, []);

        dispatch({ type: "FALTAS", load: newFaltas });
      } catch {}
    },
    [dispatch, setOperadoresData],
  );

  return callback;
};

export const useFetchPerfilesInfo = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    async sessionToken => {
      let response = await (
        await fetch(apiOrigin + "/profiles/list?expand=operator,pause_operator", {
          headers: {
            "X-Api-key": sessionToken,
          },
        })
      ).json();

      let perfiles = response.data.map(
        ({
          id,
          hearts,
          free,
          name,
          photo,
          progress_day,
          progress_max_day,
          milestone_day,
          progress_month,
          task_mails,
          task_photos,
          task_videos,
          operator,
          paused,
          pause_operator,
        }) => {
          return {
            id: id,
            nombre: name,
            apellido: "",
            progreso: Math.floor(progress_day),
            progresoMaximoDia: Math.floor(progress_max_day),
            progresoMes: Math.floor(progress_month),
            progresoMaximo: milestone_day,
            tomado: !free,
            cartas: task_mails,
            fotos: task_photos,
            videos: task_videos,
            corazones: hearts,
            fotoPerfil: photo,
            operadorAsociado: operator?.id,
            asociados: [],
            pausado: paused,
            operadorPausado: pause_operator?.id,
          };
        },
      );

      dispatch({ type: "SET_PERFILES_DATA", load: perfiles });
    },
    [dispatch],
  );

  return callback;
};

export const useTriggerOfflineMessage = () => {
  const dispatch = useDispatch();
  const offlineMessageIntervalId = useSelector(state => state.offlineMessageIntervalId);

  return () => {
    if (offlineMessageIntervalId) clearTimeout(offlineMessageIntervalId);
    const id = setTimeout(() => {
      dispatch({ type: "SET_SHOW_OFFLINE_MESSAGE", load: { show: false, id: undefined } });
    }, 10000);
    dispatch({ type: "SET_SHOW_OFFLINE_MESSAGE", load: { show: true, id: id } });
  };
};

export const useSetMostrarTomados = () => {
  const dispatch = useDispatch();

  return mostrarTomados => {
    dispatch({ type: "SET_MOSTRAR_TOMADOS", load: mostrarTomados });
  };
};

export const useLogin = () => {
  const dispatch = useDispatch();

  return (sessionToken, role, id, agency_id, shift) => {
    dispatch({
      type: "LOGIN",
      load: { sessionToken: sessionToken, role: role, id: id, agency_id: agency_id, shift: shift },
    });
    jsCookie.set("sessionToken", sessionToken);
    jsCookie.set("agency_id", agency_id);
    jsCookie.set("shift", shift);
    jsCookie.set("role", role);
    jsCookie.set("id", id);
  };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return () => {
    dispatch({ type: "LOGOUT" });
    jsCookie.remove("sessionToken");
    jsCookie.remove("role");
    jsCookie.remove("id");
    jsCookie.remove("agency_id");
    jsCookie.remove("shift");
    history.replace("/");
    queryCache.removeQueries();
  };
};

export const useAddDiaLibre = () => {
  const sessionToken = useSelector(state => state.sessionToken);
  const fetchOperadoresInfo = useFetchOperadoresInfo();

  return async (id, date) => {
    const body = {
      id: id,
      free_days: { add: [{ day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }] },
    };

    await fetch(apiOrigin + "/operators/update", {
      method: "POST",
      headers: { "x-api-key": sessionToken, "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    await fetchOperadoresInfo(sessionToken);
  };
};

export const useRemoveDiaLibre = () => {
  const sessionToken = useSelector(state => state.sessionToken);
  const fetchOperadoresInfo = useFetchOperadoresInfo();

  return async (id, date) => {
    const body = {
      id: id,
      free_days: { remove: [{ day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }] },
    };

    await fetch(apiOrigin + "/operators/update", {
      method: "POST",
      headers: { "x-api-key": sessionToken, "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    await fetchOperadoresInfo(sessionToken);
  };
};

export const useAddFalta = () => {
  const sessionToken = useSelector(state => state.sessionToken);
  const fetchOperadoresInfo = useFetchOperadoresInfo();

  return async (id, date) => {
    const body = {
      id: id,
      missed_days: { add: [{ day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }] },
    };

    await fetch(apiOrigin + "/operators/update", {
      method: "POST",
      headers: { "x-api-key": sessionToken, "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    await fetchOperadoresInfo(sessionToken);
  };
};

export const useRemoveFalta = () => {
  const sessionToken = useSelector(state => state.sessionToken);
  const fetchOperadoresInfo = useFetchOperadoresInfo();

  return async (id, date) => {
    const body = {
      id: id,
      missed_days: { remove: [{ day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }] },
    };

    await fetch(apiOrigin + "/operators/update", {
      method: "POST",
      headers: { "x-api-key": sessionToken, "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    await fetchOperadoresInfo(sessionToken);
  };
};

export const useAgregarCartasNuevasDeWS = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    cartas => {
      let newCartas = cartas.map(({ id, client_id, profile_id, timestamp, read_timestamp, replied }) => {
        return {
          cartaId: id,
          clienteId: client_id,
          perfilId: profile_id,
          tiempoLlegada: Math.floor(timestamp / 1000),
          leida: !!read_timestamp,
          respondida: replied,
        };
      });

      dispatch({ type: "SET_CARTAS_NUEVAS", load: newCartas });
    },
    [dispatch],
  );

  return callback;
};

export const useFetchCartasIniciales = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    async (sessionToken, id) => {
      const data = (
        await (
          await fetch(apiOrigin + `/operators/info?id=${id}&expand=profiles,profiles.letters,profiles.letters.client`, {
            headers: { "x-api-key": sessionToken },
          })
        ).json()
      ).data;

      const perfilesAsociados = data.profiles;

      let cartas = perfilesAsociados
        .map(perfil => {
          return perfil.letters.map(letter => ({ ...letter, profile_id: perfil.id }));
        })
        .flat();

      cartas = cartas.map(({ id, profile_id, read_timestamp, replied, timestamp, client }) => {
        return {
          cartaId: id,
          clienteId: client.id,
          perfilId: profile_id,
          tiempoLlegada: Math.floor(timestamp / 1000),
          leida: !!read_timestamp,
          respondida: replied,
        };
      });

      dispatch({ type: "SET_CARTAS", load: cartas });
    },
    [dispatch],
  );

  return callback;
};

export const usePausarOperador = () => {
  const fetchOperadorInfo = useFetchOperadorInfo();
  const fetchPerfilesInfo = useFetchPerfilesInfo();

  return async (sessionToken, id) => {
    await fetch(apiOrigin + "/operators/pause", { headers: { "x-api-key": sessionToken } });

    fetchPerfilesInfo(sessionToken);
    fetchOperadorInfo(sessionToken, id);
  };
};

export const useReanudarOperador = () => {
  const fetchOperadorInfo = useFetchOperadorInfo();
  const fetchPerfilesInfo = useFetchPerfilesInfo();

  return async (sessionToken, id) => {
    await fetch(apiOrigin + "/operators/unpause", { headers: { "x-api-key": sessionToken } });

    fetchPerfilesInfo(sessionToken);
    fetchOperadorInfo(sessionToken, id);
  };
};

export const useFetchClientesEspecificosInfo = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    async (sessionToken, clientesIdList) => {
      const clientesRaw = await Promise.all(
        clientesIdList.map(async clienteId => {
          const cliente = await (
            await fetch(apiOrigin + `/clients/info?id=${clienteId}`, {
              headers: { "x-api-key": sessionToken },
            })
          ).json();

          return cliente.data;
        }),
      );

      const clientes = clientesRaw.map(({ id, amolatina_id, name, crowns }) => {
        return {
          id,
          idAmolatina: amolatina_id,
          nombre: name,
          coronas: crowns,
        };
      });

      dispatch({ type: "SET_CLIENTES_DATA", load: { clientes } });
    },
    [dispatch],
  );

  return callback;
};

export const useSetNotificacionesCarta = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    clientesPerfilesIdList => {
      dispatch({ type: "SET_NOTIFICACIONES_CARTA", load: clientesPerfilesIdList });
    },
    [dispatch],
  );

  return callback;
};

export const useClearNotificacionesCarta = () => {
  const dispatch = useDispatch();

  const callback = useCallback(() => {
    dispatch({ type: "CLEAR_NOTIFICACIONES_CARTA" });
  }, [dispatch]);

  return callback;
};

export const useSetCartasAnteriores = () => {
  const dispatch = useDispatch();

  const callback = useCallback(
    cartasAnteriores => {
      dispatch({ type: "SET_CARTAS_ANTERIORES", load: cartasAnteriores });
    },
    [dispatch],
  );

  return callback;
};

export const useFetchClientesPaginaInfo = () => {
  const dispatch = useDispatch();
  const sessionToken = useSelector(state => state.sessionToken);

  const callback = useCallback(
    async (clientesPagina, sort, ascendente) => {
      if (!ascendente) sort = "-" + sort;

      const paginacionClientesData = await (
        await fetch(apiOrigin + `/clients/list?page=${clientesPagina}&sort=${sort}`, {
          headers: { "x-api-key": sessionToken },
        })
      ).json();

      const cantidadPaginas = paginacionClientesData._meta.pageCount;

      const clientesRaw = paginacionClientesData.data;

      const clientes = parseClientes(clientesRaw);

      dispatch({ type: "SET_CLIENTES_DATA", load: { clientes, cantidadPaginas } });
    },
    [dispatch, sessionToken],
  );

  return callback;
};

export default createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// export default createStore(reducer);
