import { ReactComponent as SpinnerSvg } from "assets/spinner.svg";
import styled, { keyframes, css } from "styled-components/macro";

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`;

const SpinnerBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1f1f26;
  z-index: 10;

  & svg {
    display: inline-block;
    width: 80px;
    height: 80px;
    fill: #17171c;
    animation: ${rotate} 1s infinite;

    ${({ pixelSize }) =>
      pixelSize
        ? css`
            width: ${pixelSize}px;
            height: ${pixelSize}px;
          `
        : null}
  }
`;

const Spinner = ({ pixelSize }) => {
  return (
    <SpinnerBg pixelSize={pixelSize}>
      <SpinnerSvg />
    </SpinnerBg>
  );
};

export default Spinner;
