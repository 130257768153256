import Login from "components/dashboards/Login";
import OfflineBadge from "components/OfflineBadge";
import { useEffect, useRef, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import store, { useSetEnLinea, useFetchOperadoresInfo, useFetchPerfilesInfo } from "./reduxStuff";
import * as serviceWorker from "./serviceWorker";
import { StyleSheetManager } from "styled-components";
import LogoutButton from "components/LogoutButton";
import { tiempoActualizacion } from "components/Utilidades";
import { queryCache, ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import GlobalStyles from "./GlobalStyles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-tabs/style/react-tabs.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { subscribeToAdmin, subscribeToUser, unsubscribe } from "./websocket";
import { useState } from "react";

window.queryCache = queryCache;

const reactQueryDefaultConfig = {
  queries: {
    refetchInterval: tiempoActualizacion,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  },
};

const DashboardAdmin = lazy(() => import("components/dashboards/DashboardAdmin/DashboardAdmin"));
const DashboardCFD = lazy(() => import("components/dashboards/DashboardCFD"));
//titleApp
const App = () => {
  const enLinea = useSelector(state => state.enLinea);
  const history = useHistory();
  const role = useSelector(state => state.role);
  const id = useSelector(state => state.id);
  const sessionToken = useSelector(state => state.sessionToken);
  const [titleApp, setTitleApp] = useState(false);
  const setEnLinea = useSetEnLinea();
  const fetchOperadoresInfo = useFetchOperadoresInfo();
  const fetchPerfilesInfo = useFetchPerfilesInfo();
  const intervalId = useRef();

  if (titleApp === false) {
    const name = window.location.host.split(".");

    document.getElementById("titleApp").innerHTML = name[0].charAt(0).toUpperCase() + name[0].slice(1);
    setTitleApp(true);
  }

  // useQuery(["operadores", new Date().getMonth() + 1, new Date().getFullYear()], fetchOperadores);

  useEffect(() => {
    unsubscribe();

    if (role === "admin") {
      subscribeToAdmin();
    } else if (role === "operator" || role === "supervisor" || role === "support" || role === "shift") {
      subscribeToUser(id);
    }
  }, [role, id]);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setEnLinea(false);
    });

    window.addEventListener("online", () => {
      setEnLinea(true);
    });
  }, [setEnLinea]);

  useEffect(() => {
    if (role === "operator" || role === "supervisor" || role === "support" || role === "shift")
      history.replace("/operador");
    if (role === "") history.replace("/");
  }, [history, history.location.pathname, role]);

  // Ciclos de fetch de estado
  useEffect(() => {
    clearInterval(intervalId.current);

    if (history.location.pathname === "/ganancia") {
      fetchPerfilesInfo(sessionToken);
      intervalId.current = setInterval(() => {
        fetchPerfilesInfo(sessionToken);
      }, tiempoActualizacion);
    } else if (history.location.pathname === "/cfd") {
      fetchOperadoresInfo(sessionToken);
      intervalId.current = setInterval(() => {
        fetchOperadoresInfo(sessionToken);
      }, tiempoActualizacion);
    }
  }, [fetchOperadoresInfo, fetchPerfilesInfo, history.location.pathname, sessionToken]);

  return (
    <>
      {sessionToken !== "" && history.location.pathname !== "/progreso" ? (
        <LogoutButton>Cerrar Sesión</LogoutButton>
      ) : null}
      {enLinea ? null : <OfflineBadge />}
      <Suspense fallback={<div>Loading...</div>}>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/admin" exact>
          <DashboardAdmin />
        </Route>
        <Route path="/operador" exact>
          <DashboardAdmin />
        </Route>
        <Route path="/cfd" exact>
          <DashboardCFD />
        </Route>
      </Suspense>
    </>
  );
};

ReactDOM.render(
  <>
    <ReactQueryConfigProvider config={reactQueryDefaultConfig}>
      <BrowserRouter>
        <Provider store={store}>
          <StyleSheetManager disableVendorPrefixes>
            <>
              <GlobalStyles />
              <App />
              <ToastContainer />
            </>
          </StyleSheetManager>
        </Provider>
      </BrowserRouter>
    </ReactQueryConfigProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
